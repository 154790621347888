import Container from "react-bootstrap/Container";
import {Col, Form, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from 'react-i18next'
import {
    Button,
    Dialog,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Slide,
    TextField
} from "@mui/material";
import {MobileDatePicker, MobileTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import {useForm} from 'react-hook-form';
import emailPlaceholder from "../../images/email-placeholder.svg";
import "moment/locale/it";
import {useMediaQuery} from "react-responsive";
import spinner from "../../images/spinner.svg";
import DialogTitle from "@mui/material/DialogTitle";
import exitIcon from "../../images/exit-icon.svg";
import DialogContent from "@mui/material/DialogContent";
import addIcon from "../../images/add.svg";
import trashIcon from "../../images/trash-icon.svg";
import FsLightbox from "fslightbox-react";
import Resizer from "react-image-file-resizer";
import DialogDoubleAction from "../components/DialogDoubleAction.js";
import CropDialog from "../components/CropDialog.js";
import themeContext from "../components/ThemeContext.js";
import ErrorPage from "../pages/ErrorPage.js";
import fileIconSquared from "../../images/file-icon-squared.svg";

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1920,
            1080,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CarClaimForm() {
    const [locale, setLocale] = useState("it");
    moment.locale(locale);
    const getClaimAttachmentUrl = `${process.env.REACT_APP_BASE_URL}/claims/wide`;
    const getPeopleUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/people`;
    const getVehiclesByPersonIdUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/peopleVehicles`;
    const getPoliciesByPersonIdUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies/people`;
    const getPolicyUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/policies`;
    const newClaimUrl = `${process.env.REACT_APP_BASE_URL}/claims/wide/new`;
    const updateClaimUrl = `${process.env.REACT_APP_BASE_URL}/claims/wide`;
    const sendClaimUrl = `${process.env.REACT_APP_BASE_URL}/claims/wide`;
    const addAttachmentToClaimUrl = `${process.env.REACT_APP_BASE_URL}/media/claims`;
    const removeAttachmentFromClaimUrl = `${process.env.REACT_APP_BASE_URL}/media/claims`;
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const defaultValues = {
        claimType: "car",
        policyId: "",
        personId: "",
        plate: "",
        dateTime: "",
        address: "",
        province: "",
        city: "",
        platesInvolved: [],
        hasAccidentReport: "false",
        description: ""
    };
    const {sendControl, handleSubmit: handleSendSubmit} = useForm();
    const {attachControl, handleSubmit: handleAttachSubmit} = useForm();
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingVehicles, setIsLoadingVehicles] = useState(false);
    const [isLoadingCreate, setIsLoadingCreate] = useState(false);
    const [isLoadingSend, setIsLoadingSend] = useState(false);
    const [isFormInvalid, setIsFormInvalid] = useState(true);
    const [isSent, setIsSent] = useState(false);
    const formValues = useRef(defaultValues);
    const platesInvolved = useRef({});
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [peopleWithVehicles, setPeopleWithVehicles] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [personPolicies, setPersonPolicies] = useState([]);
    const [isPlatesInvolved, setIsPlatesInvolved] = useState((formValues.current.platesInvolved && formValues.current.platesInvolved.length === 0) ? false : `${formValues.current.platesInvolved.length}`);
    const params = useParams();
    //const claimId = params.claimId;
    const [imagesRequired, setImagesRequired] = useState(false);
    const [isAccidentReportNote, setIsAccidentReportNote] = useState(false);
    const ids = useRef([]);
    const ids_accidentReport = useRef([]);
    const new_ids = useRef([]);
    const new_ids_accidentReport = useRef([]);
    const [images, setImages] = useState([]);
    const [imagesUrl, setImagesUrl] = useState([]);
    const [imagesAccidentalReport, setImagesAccidentalReport] = useState([]);
    const [imagesUrlAccidentalReport, setImagesUrlAccidentalReport] = useState([])
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [openUploadDialogAccidentReport, setOpenUploadDialogAccidentReport] = useState(false);
    const [openDialog2, setOpenDialog2] = useState(false);
    const [imageIdToDelete, setImageIdToDelete] = useState(-1);
    const [documents, setDocuments] = useState([]);
    const [openCropDialog, setOpenCropDialog] = useState(false);
    const [onPendingImage, setOnPendingImage] = useState("")
    const [cropper, setCropper] = useState(null);
    const [openPhotoLimitsDialog, setOpenPhotoLimitsDialog] = useState(false);
    const cropperRef = useRef(null);
    const [scaleX, setScaleX] = useState(1);
    const [scaleY, setScaleY] = useState(1);
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [claimId, setClaimId] = useState(undefined);
    const [openDoNotSaveDialog, setOpenDoNotSaveDialog] = useState(false);
    const [tempImages, setTempImages] = useState([]);
    const [tempImagesUrl, setTempImagesUrl] = useState([]);
    const [tempImagesAccidentReport, setTempImagesAccidentReport] = useState([]);
    const [tempImagesUrlAccidentReport, setTempImagesUrlAccidentReport] = useState([]);
    const [activeDialog, setActiveDialog] = useState("attach");
    const [openAccidentReportDialog, setOpenAccidentReportDialog] = useState(false);
    const [selectedPlate, setSelectedPlate] = useState("");
    const [isLoadingRemoveAccidentReportImages, setIsLoadingRemoveAccidentReportImages] = useState(false);
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });
    const [policies, setPolicies] = useState([]);
    const [selectedPolicy, setSelectedPolicy] = useState("");
    const [uploadError, setUploadError] = useState(null);

    function openLightboxOnSlide(index) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: index
        });
    }

    useEffect(() => {
        if (navigator.onLine) {
            if (claimId !== undefined) {
                formValues.current = JSON.parse(sessionStorage.getItem(`${claimId}`))
                if (formValues.current.platesInvolved.length > 0) {
                    for (let i = 0; i < formValues.current.platesInvolved.length; i++) {
                        switch (i) {
                            case 0:
                                platesInvolved.current.plate1 = formValues.current.platesInvolved[i]
                                break;
                            case 1:
                                platesInvolved.current.plate2 = formValues.current.platesInvolved[i]
                                break;
                            case 2:
                                platesInvolved.current.plate3 = formValues.current.platesInvolved[i]
                                break;
                            case 3:
                                platesInvolved.current.plate4 = formValues.current.platesInvolved[i]
                                break;
                            case 4:
                                platesInvolved.current.plate5 = formValues.current.platesInvolved[i]
                                break;
                            case 5:
                                platesInvolved.current.plate6 = formValues.current.platesInvolved[i]
                                break;
                        }
                    }
                }
                if (formValues.current.dateTime) {
                    const utcDate = moment.utc(formValues.current.dateTime).format();
                    setSelectedDate(utcDate);
                }
                if (formValues.current.hasAccidentReport === true) {
                    setIsAccidentReportNote(true)
                }
                if (formValues.current.plate) {
                    setSelectedPlate(formValues.current.plate)
                }
            }
            getPeopleWithVehicles();
            checkValidForm();
            if (sessionStorage.getItem(`${claimId}_images`) && JSON.parse(sessionStorage.getItem(`${claimId}_images`)).length > 0) {
                let claimImages = JSON.parse(sessionStorage.getItem(`${claimId}_images`));
                if (claimImages && claimImages.length > 0) {
                    ids.current = claimImages
                    for (let i = 0; i < claimImages.length; i++) {
                        getClaimAttachment(claimImages[i], 'images')
                    }
                }
            }
            if (sessionStorage.getItem(`${claimId}_accident_report_images`) && JSON.parse(sessionStorage.getItem(`${claimId}_accident_report_images`)).length > 0) {
                let claimImages = JSON.parse(sessionStorage.getItem(`${claimId}_accident_report_images`));
                if (claimImages && claimImages.length > 0) {
                    ids.current = claimImages
                    for (let i = 0; i < claimImages.length; i++) {
                        getClaimAttachment(claimImages[i], 'accidentReportImages')
                    }
                }
            }
        }
    }, []);

    function turnBack() {
        if (isSent) {
            return navigate(`/claims`);
        } else {
            return navigate(`/claims/open`);
        }
    }

    function reload() {
        window.location.reload();
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        switch (name) {
            case 'personId':
                peopleWithVehicles.filter(person => person.id === value).map((person, index) => {
                    let activeVehicles = person.vehicles.filter(vehicle => vehicle.status !== -1 && vehicle.status !== 1);
                    setVehicles(activeVehicles)
                    activeVehicles.map((vehicle, index) => {
                        if (index === 0) {
                            setSelectedPlate(vehicle.plate)
                            let activePolicies = vehicle.policies.filter(policy => policy.status !== -1 && policy.status !== 1);
                            setPolicies(activePolicies)
                            activePolicies.map((policy, index) => {
                                if (index === 0) {
                                    setSelectedPolicy(policy.id)
                                    setIsLoadingVehicles(false)
                                }
                            })
                        }
                    })
                });
                break;
            case 'plate':
                setSelectedPlate(value)
                vehicles.filter(vehicle => vehicle.plate === value).map((vehicle, index) => {
                    let activePolicies = vehicle.policies.filter(policy => policy.status !== -1 && policy.status !== 1);
                    setPolicies(activePolicies)
                    activePolicies.map((policy, index) => {
                        if (index === 0) {
                            setSelectedPolicy(policy.id)
                            setIsLoadingVehicles(false)
                        }
                    })
                })
                break;
            case 'policyId':
                setSelectedPolicy(value)
                break;
            case 'hasAccidentReport':
                if (value === true) {
                    setIsAccidentReportNote(true)
                } else {
                    if (imagesAccidentalReport.length > 0) {
                        setOpenAccidentReportDialog(true)
                    } else {
                        setIsAccidentReportNote(false)
                    }
                }
                break;
        }
        if (!(name === 'hasAccidentReport' && imagesAccidentalReport.length > 0)) {
            formValues.current = {...formValues.current, [name]: value,}
            checkValidForm();
        }
    };

    const handlePlateInputChange = (e) => {
        const {name, value} = e.target;
        platesInvolved.current = {...platesInvolved.current, [name]: value,}
    };

    const checkValidForm = () => {
        if (formValues.current.personId !== '' && formValues.current.personId !== 0)
            setIsFormInvalid(false);
    };

    const handleDateChange = (date) => {
        const utcDate = moment.utc(date).format();
        setSelectedDate(utcDate);
    };

    const submitForm = () => {
        const checkImages = JSON.parse(sessionStorage.getItem(`${claimId}_images`))
        /*
        if (claimId === undefined) {
            setImagesRequired(true)
            return;
        }  else if (checkImages.length === 0) {
            setImagesRequired(true)
            return;
        }
        */
        let plateArray = [];
        if (Object.keys(platesInvolved).length > 0) {
            for (const key in platesInvolved) {
                for (const k in platesInvolved[key]) {
                    plateArray.push(platesInvolved[key][k]);
                }
            }
        }
        formValues.current = {...formValues.current, ['platesInvolved']: plateArray,}
        formValues.current = {...formValues.current, ['dateTime']: selectedDate,}
        formValues.current = {...formValues.current, ['plate']: selectedPlate,}
        formValues.current = {...formValues.current, ['policyId']: selectedPolicy,}
        //update
        setIsLoadingSend(true)
        if (claimId === undefined) {
            fetch(`${newClaimUrl}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formValues.current)
            }).then((response) => {
                return response.json();
            }).then((res) => {
                fetch(`${updateClaimUrl}/${res.id}`, {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formValues.current)
                }).then((response) => {
                    return response.json();
                }).then((res) => {
                    if (res.id) {
                        fetch(`${sendClaimUrl}/${res.id}/send`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            },
                        }).then((response) => {
                            return response.json();
                        }).then((res) => {
                            if (res.ok) {
                                sessionStorage.setItem('root', '/claims')
                                setIsSent(true)
                            }
                        }).catch(() => {
                            setIsLoadingSend(false)
                        });
                    }
                }).catch(() => {
                    setIsLoadingSend(false)
                });
            }).catch(() => {
                setIsLoadingSend(false)
            });
        } else {
            fetch(`${updateClaimUrl}/${claimId}`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formValues.current)
            }).then((response) => {
                return response.json();
            }).then((res) => {
                if (res.id) {
                    fetch(`${sendClaimUrl}/${res.id}/send`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                    }).then((response) => {
                        return response.json();
                    }).then((res) => {
                        if (res.ok) {
                            sessionStorage.setItem('root', '/claims')
                            setIsSent(true)
                        }
                    }).catch(() => {
                        setIsLoadingSend(false)
                    });
                }
            }).catch(() => {
                setIsLoadingSend(false)
            });
        }
    }

    const createClaim = (type) => {
        if (type === 'attach') {
            setTempImagesUrl(imagesUrl)
            setTempImages(images)
        } else {
            setTempImagesUrlAccidentReport(imagesUrlAccidentalReport)
            setTempImagesAccidentReport(imagesAccidentalReport)
        }
        let plateArray = [];
        if (Object.keys(platesInvolved).length > 0) {
            for (const key in platesInvolved) {
                for (const k in platesInvolved[key]) {
                    plateArray.push(platesInvolved[key][k])
                }
            }
        }
        formValues.current = {...formValues.current, ['platesInvolved']: plateArray,}
        formValues.current = {...formValues.current, ['dateTime']: selectedDate,}
        formValues.current = {...formValues.current, ['plate']: selectedPlate,}

        if (claimId === undefined) {
            fetch(newClaimUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formValues.current)
            }).then((response) => {
                return response.json();
            }).then((res) => {
                if (res.id) {
                    sessionStorage.setItem(`${res.id}`, JSON.stringify(formValues.current))
                    if (type && type === 'attach') {
                        setOpenUploadDialog(true)
                        setActiveDialog('attach')
                        setClaimId(res.id)
                    } else if (type && type === 'attachAccidentReport') {
                        setOpenUploadDialogAccidentReport(true)
                        setClaimId(res.id)
                        setActiveDialog('attachAccidentReport')
                    }
                }
            }).catch(() => {
            });
        } else {
            //reopen attach without create
            sessionStorage.setItem(`${claimId}`, JSON.stringify(formValues.current))
            if (type && type === 'attach') {
                setOpenUploadDialog(true)
                setActiveDialog('attach')
            } else if (type && type === 'attachAccidentReport') {
                setOpenUploadDialogAccidentReport(true)
                setActiveDialog('attachAccidentReport')
            }
        }
    }

    const updateClaim = (type) => {
        let plateArray = [];
        if (Object.keys(platesInvolved).length > 0) {
            for (const key in platesInvolved) {
                for (const k in platesInvolved[key]) {
                    plateArray.push(platesInvolved[key][k])
                }
            }
        }
        formValues.current = {...formValues.current, ['platesInvolved']: plateArray,}
        formValues.current = {...formValues.current, ['dateTime']: selectedDate,}
        formValues.current = {...formValues.current, ['plate']: selectedPlate,}
        //reopen attach without create
        sessionStorage.setItem(`${claimId}`, JSON.stringify(formValues.current))
        if (type && type === 'attach') {
            setTempImagesUrl(imagesUrl)
            setTempImages(images)
            setOpenUploadDialog(true)
            setActiveDialog('attach')
        } else if (type && type === 'attachAccidentReport') {
            setTempImagesUrlAccidentReport(imagesUrlAccidentalReport)
            setTempImagesAccidentReport(imagesAccidentalReport)
            setOpenUploadDialogAccidentReport(true)
            setActiveDialog('attachAccidentReport')
        }
    }

    const getPeopleWithVehicles = () => {
        setIsLoadingData(true)
        fetch(getPeopleUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            data.map((person, index) => {
                let vehicleFound = false;
                if (person.status !== -1) {
                    person.vehicles.map((vehicle, index) => {
                        if (vehicle.status !== -1 && vehicle.status !== 1) {
                            vehicleFound = true;
                        }
                        if ((index + 1 === person.vehicles.length) && vehicleFound) {
                            setPeopleWithVehicles(current => [...current, person])
                        }
                        if ((index + 1 === person.vehicles.length) && formValues.current.plate && vehicleFound) {
                            setSelectedPlate(formValues.current.plate)
                        }
                    })
                }
            })
            if (formValues.current.personId) {
                data.filter(person => person.id === formValues.current.personId).map((person, index) => {
                    let activeVehicles = person.vehicles.filter(vehicle => vehicle.status !== -1 && vehicle.status !== 1);
                    setVehicles(activeVehicles)
                    activeVehicles.map((vehicle, index) => {
                        if (index === 0) {
                            setSelectedPlate(vehicle.plate)
                            let activePolicies = vehicle.policies.filter(policy => policy.status !== -1 && policy.status !== 1);
                            setPolicies(activePolicies)
                            activePolicies.map((policy, index) => {
                                if (index === 0) {
                                    setSelectedPolicy(policy.id)
                                    setIsLoadingVehicles(false)
                                }
                            })
                        }
                    })
                });
            }
            setIsLoadingData(false)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const getVehicleByPersonId = (personId) => {
        setIsLoadingVehicles(true)
        fetch(`${getVehiclesByPersonIdUrl}?personId=${personId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setVehicles(data)
            setSelectedPlate(data[0].plate)
            setIsLoadingVehicles(false)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const getClaimAttachment = (attachmentId, type) => {
        setIsLoadingData(true)
        fetch(`${getClaimAttachmentUrl}/${claimId}/attachment/${attachmentId}/download`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.blob();
        }).then((data) => {
            if (data) {
                let urlCreator = window.URL || window.webkitURL;
                let imageUrl = urlCreator.createObjectURL(data);
                if (type === 'images') {
                    if (data.type === 'application/pdf') {
                        setImages(current => [...current, {id: attachmentId, url: fileIconSquared, file: imageUrl}])
                    } else {
                        setImages(current => [...current, {id: attachmentId, url: imageUrl}])
                        setImagesUrl(current => [...current, imageUrl])
                    }
                } else if (type === 'accidentReportImages') {
                    if (data.type === 'application/pdf') {
                        setImagesAccidentalReport(current => [...current, {
                            id: attachmentId,
                            url: fileIconSquared,
                            file: imageUrl
                        }])
                    } else {
                        setImagesAccidentalReport(current => [...current, {id: attachmentId, url: imageUrl}])
                        setImagesUrlAccidentalReport(current => [...current, imageUrl])
                    }
                }
                setIsLoadingData(false)
            }
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const openDeleteImageDialog = (id) => {
        setImageIdToDelete(id);
        setOpenDialog2(true)
    }

    const handleDeleteImage = (attachmentId) => {
        fetch(`${removeAttachmentFromClaimUrl}/${claimId}/attachments/${attachmentId}/remove`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.ok) {
                setUploadError(null)
                if (activeDialog === 'attach') {
                    ids.current = ids.current.filter(id => {
                        return id !== attachmentId;
                    })
                    setImages([])
                    setImagesUrl([])
                    if (window.location.href.includes('attachAccidentReportImages')) {
                        sessionStorage.setItem(`${claimId}_accident_report_images`, JSON.stringify(ids.current))
                    } else {
                        sessionStorage.setItem(`${claimId}_images`, JSON.stringify(ids.current))
                    }
                    if (ids.current.length > 0) {
                        for (let i = 0; i < ids.current.length; i++) {
                            if (ids.current[i] !== attachmentId) {
                                getClaimAttachment(ids.current[i], 'images');
                            }
                        }
                    }
                } else {
                    ids_accidentReport.current = ids_accidentReport.current.filter(id => {
                        return id !== attachmentId;
                    })
                    setImagesAccidentalReport([])
                    setImagesUrlAccidentalReport([])
                    if (window.location.href.includes('attachAccidentReportImages')) {
                        sessionStorage.setItem(`${claimId}_accident_report_images`, JSON.stringify(ids.current))
                    } else {
                        sessionStorage.setItem(`${claimId}_images`, JSON.stringify(ids.current))
                    }
                    if (ids_accidentReport.current.length > 0) {
                        for (let i = 0; i < ids_accidentReport.current.length; i++) {
                            if (ids_accidentReport.current[i] !== attachmentId) {
                                getClaimAttachment(ids_accidentReport.current[i], 'accidentReportImages');
                            }
                        }
                    }
                }
            }
            setOpenDialog2(false)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const attachImageActions = (documentType, imagesLength) => {
        if (imagesLength >= 15) {
            setOpenPhotoLimitsDialog(true);
        } else {
            handleChangeImage();
        }
    }

    const handleChangeImage = () => {
        const input = document.querySelector("#image-file");
        input.click();
    }

    const handleOnPendingImage = async (e) => {
        setUploadError(null)
        try {
            e.preventDefault();
            let file = e.target.files[0];
            if (file && !file.type.includes('image') && file.type !== 'application/pdf') {
                throw 'File not supported';
            }
            if (file && file.size > 20000000) {
                throw 'Maximum upload size exceeded';
            }
            if (file.type === 'application/pdf') {
                const randomName = Math.floor((Math.random() * 100000000) + 1)
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];
                    addAttachment(randomName, file.type, base64String);
                };
                reader.readAsDataURL(file);
            } else {
                const imageResized = await resizeFile(file)
                setOnPendingImage(imageResized);
                setOpenCropDialog(true)
            }
        } catch (err) {
            if (err === 'Maximum upload size exceeded') {
                setUploadError(t('File not supported') + '<br />' + t('The maximum file upload size is 20MB'))
            } else if ('File not supported') {
                setUploadError(t('The uploaded format is not supported') + '<br />' + t('Only image formats are supported'))
            }
        }
    }

    const addAttachment = (randomName, mimetype, base64_string) => {
        fetch(`${addAttachmentToClaimUrl}/${claimId}/attachments/new`, {
            method: 'POST',
            body: JSON.stringify({
                name: randomName,
                mimeType: mimetype,
                base64: base64_string,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin': true
            },
        }).then((res) => {
            if (res.ok) {
                //alert upload completato
                return res.json();
            }
        }).then((res) => {
            setIsLoadingImg(false);
            setOpenCropDialog(false);
            if (activeDialog === 'attach') {
                ids.current.push(res.id)
                new_ids.current.push(res.id)
            } else {
                ids_accidentReport.current.push(res.id)
                new_ids_accidentReport.current.push(res.id)
            }
            return res.id;
        }).then((attachment) => {
            if (activeDialog === 'attach') {
                getClaimAttachment(attachment, 'images');
            } else {
                getClaimAttachment(attachment, 'accidentReportImages');
            }
        }).catch(() => {
            setErrorPage(true)
        });
    }

    const attachCroppedImage = () => {
        let cropImage = "#";
        if (typeof cropper !== "undefined") {
            cropImage = cropper.getCanvas().toDataURL()
        }
        if (cropImage !== "#") {
            const randomName = Math.floor((Math.random() * 100000000) + 1)
            let split1 = cropImage.split(":");
            let split2 = split1[1].split(";");
            let mimetype = split2[0];
            let base64_fullString = cropImage.split(",");
            let base64_string = base64_fullString[1];
            setIsLoadingImg(true);
            addAttachment(randomName, mimetype, base64_string);
        }
    }

    const doNotSave = (type) => {
        if (type === 'attach') {
            setOpenDoNotSaveDialog(false)
            setOpenUploadDialog(false)
            setImages(tempImages)
            setImagesUrl(tempImagesUrl)

            ids.current = ids.current.filter(id_to_maintain => {
                if (!new_ids.current.includes(id_to_maintain)) {
                    return id_to_maintain
                }
            })
            new_ids.current = []
        } else if (type === 'attachAccidentReport') {
            setOpenDoNotSaveDialog(false)
            setOpenUploadDialogAccidentReport(false)
            setImagesAccidentalReport(tempImagesAccidentReport)
            setImagesUrlAccidentalReport(tempImagesUrlAccidentReport)

            ids_accidentReport.current = ids_accidentReport.current.filter(id_to_maintain => {
                if (!new_ids_accidentReport.current.includes(id_to_maintain)) {
                    return id_to_maintain
                }
            })
            new_ids_accidentReport.current = []
        }
    }

    const removeAccidentReportImages = () => {
        imagesAccidentalReport.map((image, index) => {
            setIsLoadingRemoveAccidentReportImages(true)
            handleDeleteImage(image.id)
            if (index + 1 === imagesAccidentalReport.length) {
                formValues.current.hasAccidentReport = false;
                checkValidForm();
                setImagesAccidentalReport([])
                setImagesUrlAccidentalReport([])
                setTempImagesAccidentReport([])
                setTempImagesUrlAccidentReport([])
                setIsAccidentReportNote(false)
                setIsLoadingRemoveAccidentReportImages(false)
                setOpenAccidentReportDialog(false)
            }
        });
    }

    const openLightboxFun = (list, index) => {
        // counts how many elements in the list have the key ‘file’ before index
        let count = 0;
        for (let i = 0; i < index; i++) {
            if (list[i].file) {
                count++;
            }
        }
        openLightboxOnSlide(index - count);
    }

    const handlePlatesInvolvedChange = (value) => {
        setIsPlatesInvolved(value);
        if (Object.keys(platesInvolved.current).length > 0) {
            for (let i = value; i <= 6; i++) {
                switch (i) {
                    case 1:
                        delete platesInvolved.current.plate1;
                        break;
                    case 2:
                        delete platesInvolved.current.plate2;
                        break;
                    case 3:
                        delete platesInvolved.current.plate3;
                        break;
                    case 4:
                        delete platesInvolved.current.plate4;
                        break;
                    case 5:
                        delete platesInvolved.current.plate5;
                        break;
                    case 6:
                        delete platesInvolved.current.plate6;
                        break;
                }
            }
        }
    };

    return (
        errorPage ?
            <ErrorPage/> :
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}
                                  localeText={{
                                      cancelButtonLabel: t('dialog.button-cancel'),
                                      okButtonLabel: t('dialog.button-confirm'),
                                  }}>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.neutral10}}>
                    <Row className="container nav-top border-bottom overZIndex" style={{
                        'backgroundColor': themeContext.color.white,
                        'paddingTop': '19.5px',
                        'paddingBottom': '20px',
                        'paddingLeft': '0',
                        'paddingRight': '0'
                    }}>
                        <Col className="col-2 float-left">
                            <img src={back} onClick={turnBack}
                                 className="nav-top-logo float-left cursor-pointer"
                                 height="27px"
                                 alt="back"/>
                        </Col>
                        <Col className="col-8">
                            <h6 className="header-title ellipsis-1-v2">{t('carClaimForm.title')}</h6>
                        </Col>
                        <Col className="col-2">

                        </Col>
                    </Row>

                    <Container className="top-of-the-payment-title-detail"></Container>
                    {
                        isLoadingData ?
                            <div style={{'height': '90vh'}} className="background-loading-spinner"></div>
                            :
                            <Fragment>
                                {
                                    !isSent &&
                                    <Fragment>
                                        <Row className="mt-1" style={{
                                            paddingLeft: '0',
                                            paddingRight: '0',
                                            textAlign: 'left',
                                            fontSize: '14px',
                                            color: themeContext.color.neutral400
                                        }}>
                                            <Col>
                                                <span>{t('carClaimForm.payoff-1')}</span>
                                                <span className="font-weight-bold">{t('carClaimForm.payoff-2')}</span>
                                            </Col>
                                        </Row>
                                        <Form
                                            onSubmit={handleSendSubmit(submitForm)}>
                                            <FormControl required variant="outlined" className={'w-100'}>
                                                <InputLabel id="person-label"
                                                            className="mt-4">{t('carClaimForm.person-label')}</InputLabel>
                                                <Select
                                                    disabled={isLoadingData}
                                                    className="mt-4"
                                                    labelId="person-label"
                                                    id="personId"
                                                    name="personId"
                                                    control={sendControl}
                                                    value={formValues.current.personId}
                                                    onChange={handleInputChange}
                                                    label={t('carClaimForm.person-label')}
                                                    fullWidth
                                                    style={{
                                                        'backgroundColor': themeContext.color.white,
                                                        'textAlign': 'left'
                                                    }}
                                                    SelectDisplayProps={{
                                                        style: {
                                                            'textAlign': 'left',
                                                            'borderRadius': '4px',
                                                            'color': 'themeContext.color.neutral600',
                                                            'width': '100%',
                                                        }
                                                    }}>
                                                    {
                                                        peopleWithVehicles.map((value, index) => (
                                                            <MenuItem key={index + 1} value={value.id}>
                                                                {((value.firstName === null || value.firstName === "") && (value.lastName === null || value.lastName === "")) ?
                                                                    <span>{value.name}</span> :
                                                                    <span>{value.firstName} {value.lastName}</span>}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                            <FormControl required variant="outlined"
                                                         className={'w-100'} style={{position: 'relative'}}>
                                                {isLoadingVehicles &&
                                                    <div className={'position-absolute spinner-inner-select'}>
                                                        <img src={spinner} className="spinner-img" alt="spinner"/>
                                                    </div>
                                                }
                                                <InputLabel id="plate-label"
                                                            className="mt-4">{t('carClaimForm.plate-label')}</InputLabel>
                                                <Select
                                                    displayEmpty
                                                    disabled={isLoadingVehicles || formValues.current.personId === ""}
                                                    className="mt-4"
                                                    labelId="plate-label"
                                                    id="plate"
                                                    name="plate"
                                                    value={selectedPlate}
                                                    control={sendControl}
                                                    onChange={handleInputChange}
                                                    //onChange={handlePlateSelectChange}
                                                    label={t('carClaimForm.plate-label')}
                                                    fullWidth
                                                    style={{
                                                        'backgroundColor': themeContext.color.white,
                                                        'textAlign': 'left'
                                                    }}
                                                    SelectDisplayProps={
                                                        isLoadingVehicles ? {
                                                            style: {
                                                                'opacity': '0.4',
                                                                'borderRadius': '4px',
                                                                'color': 'themeContext.color.neutral600',
                                                                'width': '100%'
                                                            }
                                                        } : {
                                                            style: {
                                                                'borderRadius': '4px',
                                                                'color': 'themeContext.color.neutral600',
                                                                'width': '100%'
                                                            }
                                                        }}>
                                                    {
                                                        vehicles.map((vehicle, index) => (
                                                            <MenuItem key={index + 1} value={vehicle.plate}>
                                                                {vehicle.plate} - {vehicle.modellocostruttore}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                            <FormControl required variant="outlined"
                                                         className={'w-100'} style={{position: 'relative'}}>
                                                {isLoadingVehicles &&
                                                    <div className={'position-absolute spinner-inner-select'}>
                                                        <img src={spinner} className="spinner-img" alt="spinner"/>
                                                    </div>
                                                }
                                                <InputLabel id="policy-label"
                                                            className="mt-4">{t('carClaimForm.claimed-policy')}</InputLabel>
                                                <Select
                                                    displayEmpty
                                                    disabled={isLoadingVehicles || formValues.current.personId === ""}
                                                    className="mt-4"
                                                    labelId="policy-label"
                                                    id="policyId"
                                                    name="policyId"
                                                    value={selectedPolicy}
                                                    control={sendControl}
                                                    onChange={handleInputChange}
                                                    label={t('carClaimForm.claimed-policy')}
                                                    fullWidth
                                                    style={{
                                                        'backgroundColor': themeContext.color.white,
                                                        'textAlign': 'left'
                                                    }}
                                                    SelectDisplayProps={
                                                        isLoadingVehicles ? {
                                                            style: {
                                                                'opacity': '0.4',
                                                                'borderRadius': '4px',
                                                                'color': 'themeContext.color.neutral600',
                                                                'width': '100%'
                                                            }
                                                        } : {
                                                            style: {
                                                                'borderRadius': '4px',
                                                                'color': 'themeContext.color.neutral600',
                                                                'width': '100%'
                                                            }
                                                        }}>
                                                    {
                                                        policies.map((policy, index) => (
                                                            <MenuItem key={index + 1} value={policy.id}>
                                                                {policy.type} - {policy.company}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                                <FormHelperText>{t('carClaimForm.claimed-policy-helper-text')}</FormHelperText>
                                            </FormControl>
                                            <Row className="mt-4 mb-2">
                                                <Col>
                                                    <MobileDatePicker
                                                        className="w-100" id="date" name="date"
                                                        required
                                                        label={t('carClaimForm.date-label')}
                                                        value={selectedDate}
                                                        control={sendControl}
                                                        onChange={handleDateChange}
                                                        renderInput={(params) => <TextField
                                                            style={{backgroundColor: themeContext.color.white}} {...params} />}
                                                        format="DD/MM/YYYY"
                                                        disableFuture/>
                                                </Col>
                                            </Row>
                                            <Row className="mt-4">
                                                <Col>
                                                    <MobileTimePicker
                                                        className="w-100" id="time" name="time"
                                                        required
                                                        label={t('carClaimForm.time-label')}
                                                        value={selectedDate}
                                                        control={sendControl}
                                                        onChange={handleDateChange}
                                                        renderInput={(params) => <TextField
                                                            style={{backgroundColor: themeContext.color.white}} {...params} />}
                                                        ampm={false}/>
                                                </Col>
                                            </Row>
                                            <Row className="mt-4" style={{
                                                paddingLeft: '0',
                                                paddingRight: '0',
                                                textAlign: 'left',
                                                fontSize: '14px',
                                                color: 'themeContext.color.neutral600'
                                            }}>
                                                <Col>
                                                    <span>{t('carClaimForm.location-label')}</span>
                                                </Col>
                                            </Row>
                                            <TextField
                                                id="address"
                                                name="address"
                                                className="mt-3"
                                                fullWidth
                                                label={t('carClaimForm.address-label')}
                                                variant="outlined"
                                                type="text"
                                                control={sendControl}
                                                defaultValue={formValues.current.address}
                                                onChange={handleInputChange}
                                                color="primary"
                                                style={{'backgroundColor': themeContext.color.white}}
                                                InputProps={{
                                                    style: {
                                                        'borderRadius': '4px',
                                                        'color': 'themeContext.color.neutral600'
                                                    }
                                                }}
                                            />
                                            <TextField
                                                id="province"
                                                name="province"
                                                className="mt-4"
                                                fullWidth
                                                control={sendControl}
                                                label={t('carClaimForm.province-label')}
                                                variant="outlined"
                                                type="text"
                                                defaultValue={formValues.current.province}
                                                onChange={handleInputChange}
                                                color="primary"
                                                style={{'backgroundColor': themeContext.color.white}}
                                                InputProps={{
                                                    style: {
                                                        'borderRadius': '4px',
                                                        'color': themeContext.color.neutral600
                                                    }
                                                }}
                                            />
                                            <TextField
                                                id="city"
                                                name="city"
                                                className="mt-4"
                                                fullWidth
                                                label={t('carClaimForm.city-label')}
                                                variant="outlined"
                                                type="text"
                                                control={sendControl}
                                                defaultValue={formValues.current.city}
                                                onChange={handleInputChange}
                                                color="primary"
                                                style={{'backgroundColor': themeContext.color.white}}
                                                InputProps={{
                                                    style: {
                                                        'borderRadius': '4px',
                                                        'color': themeContext.color.neutral600
                                                    }
                                                }}
                                            />
                                            <Row className="mt-4" style={{
                                                paddingLeft: '0',
                                                paddingRight: '0',
                                                textAlign: 'left',
                                                fontSize: '14px',
                                                color: themeContext.color.neutral600
                                            }}>
                                                <Col>
                                                    <span>{t('carClaimForm.platesInvolved-label')}</span>
                                                </Col>
                                            </Row>
                                            <FormControl required variant="outlined"
                                                         className={'w-100'}>
                                                <Select
                                                    className="mt-4"
                                                    id="platesInvolved"
                                                    name="platesInvolved"
                                                    control={sendControl}
                                                    onChange={(e) => handlePlatesInvolvedChange(e.target.value)}
                                                    fullWidth
                                                    value={isPlatesInvolved}
                                                    style={{
                                                        'backgroundColor': themeContext.color.white,
                                                        'textAlign': 'left'
                                                    }}
                                                    SelectDisplayProps={{
                                                        style: {
                                                            'borderRadius': '4px',
                                                            'color': themeContext.color.neutral600,
                                                            'width': '100%'
                                                        }
                                                    }}
                                                >
                                                    <MenuItem value={false}><em style={{'fontStyle': 'normal'}}>No</em></MenuItem>
                                                    <MenuItem value='1'><em
                                                        style={{'fontStyle': 'normal'}}>1</em></MenuItem>
                                                    <MenuItem value='2'><em
                                                        style={{'fontStyle': 'normal'}}>2</em></MenuItem>
                                                    <MenuItem value='3'><em
                                                        style={{'fontStyle': 'normal'}}>3</em></MenuItem>
                                                    <MenuItem value='4'><em
                                                        style={{'fontStyle': 'normal'}}>4</em></MenuItem>
                                                    <MenuItem value='5'><em
                                                        style={{'fontStyle': 'normal'}}>5</em></MenuItem>
                                                    <MenuItem value='6'><em
                                                        style={{'fontStyle': 'normal'}}>6</em></MenuItem>
                                                </Select>
                                            </FormControl>
                                            {
                                                ((isPlatesInvolved && isPlatesInvolved >= 1)) &&
                                                <Fragment>
                                                    <Row className="mt-4" style={{
                                                        paddingLeft: '0',
                                                        paddingRight: '0',
                                                        textAlign: 'left',
                                                        fontSize: '14px',
                                                        color: themeContext.color.neutral600
                                                    }}>
                                                        <Col>
                                                            <span>{t('carClaimForm.plate-labels')}</span>
                                                        </Col>
                                                    </Row>
                                                    <TextField
                                                        required={true}
                                                        id={`plate1`}
                                                        name={`plate1`}
                                                        className="mt-4 mb-4"
                                                        fullWidth
                                                        label={`${t('carClaimForm.plate-label')} 1`}
                                                        placeholder={`${t('carClaimForm.plate-label')} 1`}
                                                        variant="outlined"
                                                        type="text"
                                                        defaultValue={platesInvolved.current.plate1}
                                                        onChange={handlePlateInputChange}
                                                        color="primary"
                                                        style={{'backgroundColor': themeContext.color.white}}
                                                        InputProps={{
                                                            style: {
                                                                'borderRadius': '4px',
                                                                'color': themeContext.color.neutral600
                                                            }
                                                        }}
                                                    />
                                                </Fragment>
                                            }
                                            {
                                                ((isPlatesInvolved && isPlatesInvolved >= 2)) &&
                                                <TextField
                                                    required={true}
                                                    id={`plate2`}
                                                    name={`plate2`}
                                                    className="mb-4"
                                                    fullWidth
                                                    label={`${t('carClaimForm.plate-label')} 2`}
                                                    placeholder={`${t('carClaimForm.plate-label')} 2`}
                                                    variant="outlined"
                                                    type="text"
                                                    defaultValue={platesInvolved.current.plate2}
                                                    onChange={handlePlateInputChange}
                                                    color="primary"
                                                    style={{'backgroundColor': themeContext.color.white}}
                                                    InputProps={{
                                                        style: {
                                                            'borderRadius': '4px',
                                                            'color': themeContext.color.neutral600
                                                        }
                                                    }}
                                                />
                                            }
                                            {
                                                ((isPlatesInvolved && isPlatesInvolved >= 3)) &&
                                                <TextField
                                                    required={true}
                                                    id={`plate3`}
                                                    name={`plate3`}
                                                    className="mb-4"
                                                    fullWidth
                                                    label={`${t('carClaimForm.plate-label')} 3`}
                                                    placeholder={`${t('carClaimForm.plate-label')} 3`}
                                                    variant="outlined"
                                                    type="text"
                                                    defaultValue={platesInvolved.current.plate3}
                                                    onChange={handlePlateInputChange}
                                                    color="primary"
                                                    style={{'backgroundColor': themeContext.color.white}}
                                                    InputProps={{
                                                        style: {
                                                            'borderRadius': '4px',
                                                            'color': themeContext.color.neutral600
                                                        }
                                                    }}
                                                />
                                            }
                                            {
                                                ((isPlatesInvolved && isPlatesInvolved >= 4)) &&
                                                <TextField
                                                    required={true}
                                                    id={`plate4`}
                                                    name={`plate4`}
                                                    className="mb-4"
                                                    fullWidth
                                                    label={`${t('carClaimForm.plate-label')} 4`}
                                                    placeholder={`${t('carClaimForm.plate-label')} 4`}
                                                    variant="outlined"
                                                    type="text"
                                                    defaultValue={platesInvolved.current.plate4}
                                                    onChange={handlePlateInputChange}
                                                    color="primary"
                                                    style={{'backgroundColor': themeContext.color.white}}
                                                    InputProps={{
                                                        style: {
                                                            'borderRadius': '4px',
                                                            'color': themeContext.color.neutral600
                                                        }
                                                    }}
                                                />
                                            }
                                            {
                                                ((isPlatesInvolved && isPlatesInvolved >= 5)) &&
                                                <TextField
                                                    required={true}
                                                    id={`plate5`}
                                                    name={`plate5`}
                                                    className="mb-4"
                                                    fullWidth
                                                    label={`${t('carClaimForm.plate-label')} 5`}
                                                    placeholder={`${t('carClaimForm.plate-label')} 5`}
                                                    variant="outlined"
                                                    type="text"
                                                    defaultValue={platesInvolved.current.plate5}
                                                    onChange={handlePlateInputChange}
                                                    color="primary"
                                                    style={{'backgroundColor': themeContext.color.white}}
                                                    InputProps={{
                                                        style: {
                                                            'borderRadius': '4px',
                                                            'color': themeContext.color.neutral600
                                                        }
                                                    }}
                                                />
                                            }
                                            {
                                                ((isPlatesInvolved && isPlatesInvolved >= 6)) &&
                                                <TextField
                                                    required={true}
                                                    id={`plate6`}
                                                    name={`plate6`}
                                                    className="mb-4"
                                                    fullWidth
                                                    label={`${t('carClaimForm.plate-label')} 6`}
                                                    placeholder={`${t('carClaimForm.plate-label')} 6`}
                                                    variant="outlined"
                                                    type="text"
                                                    defaultValue={platesInvolved.current.plate6}
                                                    onChange={handlePlateInputChange}
                                                    color="primary"
                                                    style={{'backgroundColor': themeContext.color.white}}
                                                    InputProps={{
                                                        style: {
                                                            'borderRadius': '4px',
                                                            'color': themeContext.color.neutral600
                                                        }
                                                    }}
                                                />
                                            }
                                            <TextField
                                                multiline
                                                id="description"
                                                name="description"
                                                className="mt-4 mb-4"
                                                minRows={7}
                                                fullWidth
                                                control={sendControl}
                                                label={t('carClaimForm.description-label')}
                                                placeholder={t('carClaimForm.description-label')}
                                                variant="outlined"
                                                type="text"
                                                defaultValue={formValues.current.description}
                                                onChange={handleInputChange}
                                                color="primary"
                                                style={{'backgroundColor': themeContext.color.white}}
                                                InputProps={{
                                                    style: {
                                                        'borderRadius': '4px',
                                                        'color': themeContext.color.neutral600
                                                    }
                                                }}
                                            />
                                            <Row className="mt-4 mb-4 justify-content-center">
                                                {
                                                    isDesktop && sessionStorage.getItem(`${claimId}_images`) && JSON.parse(sessionStorage.getItem(`${claimId}_images`)).length > 0 && sessionStorage.getItem(`${claimId}_images`).length > 0 && images.map((item, index) => (
                                                        <div key={index}>
                                                            {
                                                                index <= 2 &&
                                                                <Fragment>
                                                                    <div className="container" style={{
                                                                        position: 'relative',
                                                                        textAlign: 'center',
                                                                        padding: '0'
                                                                    }}>
                                                                        <img className={'image-fit'} style={{
                                                                            width: '100px',
                                                                            height: '100px',
                                                                            borderRadius: '4px',
                                                                            marginRight: '10px',
                                                                            marginLeft: '10px'
                                                                        }} src={item.url} alt={`claim_${index}`}
                                                                             onClick={claimId === undefined ? () => createClaim(`attach`) : () => updateClaim(`attach`)}/>
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                            {
                                                                index === 3 &&
                                                                <Fragment>
                                                                    <div className="container" style={{
                                                                        position: 'relative',
                                                                        textAlign: 'center',
                                                                        padding: '0'
                                                                    }}>
                                                                        <img className={'image-fit'} style={{
                                                                            width: '100px',
                                                                            height: '100px',
                                                                            borderRadius: '4px',
                                                                            marginRight: '10px',
                                                                            marginLeft: '10px',
                                                                            opacity: '0.5'
                                                                        }} src={item.url} alt={`claim_${index}`}
                                                                             onClick={claimId === undefined ? () => createClaim(`attach`) : () => updateClaim(`attach`)}/>
                                                                        <div style={{
                                                                            position: 'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform: 'translate(-50%, -50%)',
                                                                            fontWeight: '600',
                                                                            fontSize: '32px',
                                                                            color: themeContext.color.neutral600
                                                                        }}
                                                                             onClick={claimId === undefined ? () => createClaim(`attach`) : () => updateClaim(`attach`)}>
                                                                            +{images.length - 3}</div>
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    !isDesktop && sessionStorage.getItem(`${claimId}_images`) && JSON.parse(sessionStorage.getItem(`${claimId}_images`)).length > 0 && sessionStorage.getItem(`${claimId}_images`).length > 0 && images.map((item, index) => (
                                                        <div key={index}>
                                                            {
                                                                index <= 1 &&
                                                                <Fragment>
                                                                    <div className="container" style={{
                                                                        position: 'relative',
                                                                        textAlign: 'center',
                                                                        padding: '0'
                                                                    }}>
                                                                        <img className={'image-fit'} style={{
                                                                            width: '100px',
                                                                            height: '100px',
                                                                            borderRadius: '4px',
                                                                            marginRight: '10px',
                                                                            marginLeft: '10px'
                                                                        }} src={item.url} alt={`claim_${index}`}
                                                                             onClick={claimId === undefined ? () => createClaim(`attach`) : () => updateClaim(`attach`)}/>
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                            {
                                                                index === 2 &&
                                                                <Fragment>
                                                                    <div className="container" style={{
                                                                        position: 'relative',
                                                                        textAlign: 'center',
                                                                        padding: '0'
                                                                    }}>
                                                                        <img className={'image-fit'} style={{
                                                                            width: '100px',
                                                                            height: '100px',
                                                                            borderRadius: '4px',
                                                                            marginRight: '10px',
                                                                            marginLeft: '10px',
                                                                            opacity: '0.5'
                                                                        }} src={item.url}
                                                                             onClick={claimId === undefined ? () => createClaim(`attach`) : () => updateClaim(`attach`)}/>
                                                                        <div style={{
                                                                            position: 'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            transform: 'translate(-50%, -50%)',
                                                                            fontWeight: '600',
                                                                            fontSize: '32px',
                                                                            color: themeContext.color.neutral600
                                                                        }}
                                                                             onClick={claimId === undefined ? () => createClaim(`attach`) : () => updateClaim(`attach`)}>
                                                                            +{images.length - 2}</div>
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </Row>
                                            <Row className="justify-content-center mb-3">
                                                <Col>
                                                    <Button name="attach" fullWidth
                                                            disabled={isFormInvalid}
                                                            type={"button"}
                                                            onClick={claimId === undefined ? () => createClaim(`attach`) : () => updateClaim(`attach`)}
                                                            className={isFormInvalid ? "btn-custom-disable-2 cursor-pointer" : "btn-primary-custom cursor-pointer"}
                                                            style={isFormInvalid ? {
                                                                background: themeContext.color.neutral400,
                                                                height: '40px',
                                                                textTransform: 'none',
                                                                width: isDesktop ? 'max-content' : '-webkit-fill-available'
                                                            } : {
                                                                background: themeContext.color.main,
                                                                height: '40px',
                                                                textTransform: 'none',
                                                                width: isDesktop ? 'max-content' : '-webkit-fill-available'
                                                            }}>
                                                        {
                                                            isLoadingCreate &&
                                                            <img src={spinner} className="spinner-img" alt="spinner"/>
                                                        }
                                                        {
                                                            !isLoadingCreate && t('carClaimForm.append-image-label')
                                                        }
                                                    </Button>
                                                </Col>
                                                {
                                                    imagesRequired &&
                                                    <Col className="col-auto mt-1">
                                                        <span className="footer-text"
                                                              style={{
                                                                  'color': themeContext.color.red,
                                                                  'paddingTop': '5px'
                                                              }}>
                                                            {t('carClaimForm.attach-image-error')}
                                                    </span>
                                                    </Col>
                                                }
                                            </Row>
                                            <Row className="mt-2 ml-2 mr-2 mb-4">
                                                <Col className="col" style={{'color': themeContext.color.neutral600}}>
                                                    {t('carClaimForm.upload-description')}
                                                </Col>
                                            </Row>
                                            <FormControl variant="outlined"
                                                         className={'w-100'}>
                                                <InputLabel id="hasAccidentReport-label"
                                                            className="mt-4 float-left">{t('carClaimForm.hasAccidentReport-label')}</InputLabel>
                                                <Select
                                                    className="mt-4 mb-4"
                                                    id="hasAccidentReport"
                                                    name="hasAccidentReport"
                                                    labelId="hasAccidentReport-label"
                                                    value={formValues.current.hasAccidentReport}
                                                    onChange={handleInputChange}
                                                    fullWidth
                                                    control={sendControl}
                                                    label={t('carClaimForm.hasAccidentReport-label')}
                                                    style={{
                                                        'backgroundColor': themeContext.color.white,
                                                        'textAlign': 'left'
                                                    }}
                                                    SelectDisplayProps={{
                                                        style: {
                                                            'borderRadius': '4px',
                                                            'color': themeContext.color.neutral600,
                                                            'width': '100%'
                                                        }
                                                    }}>
                                                    <MenuItem value={false}><em style={{'fontStyle': 'normal'}}>No</em></MenuItem>
                                                    <MenuItem value={true}><em
                                                        style={{'fontStyle': 'normal'}}>Sì</em></MenuItem>
                                                </Select>
                                            </FormControl>
                                            {
                                                isAccidentReportNote === true &&
                                                <Fragment>
                                                    <Row className="mt-4 mb-4 justify-content-center">
                                                        {
                                                            isDesktop && sessionStorage.getItem(`${claimId}_accident_report_images`) && sessionStorage.getItem(`${claimId}_accident_report_images`).length > 0 && imagesAccidentalReport.map((item, index) => (
                                                                <div key={index}>
                                                                    {
                                                                        index <= 2 &&
                                                                        <Fragment>
                                                                            <div className="container" style={{
                                                                                position: 'relative',
                                                                                textAlign: 'center',
                                                                                padding: '0'
                                                                            }}>
                                                                                <img className={'image-fit'} style={{
                                                                                    width: '100px',
                                                                                    height: '100px',
                                                                                    borderRadius: '4px',
                                                                                    marginRight: '10px',
                                                                                    marginLeft: '10px'
                                                                                }} src={item.url} alt={`claim_${index}`}
                                                                                     onClick={claimId === undefined ? () => createClaim(`attachAccidentReport`) : () => updateClaim(`attachAccidentReport`)}/>
                                                                            </div>
                                                                        </Fragment>
                                                                    }
                                                                    {
                                                                        index === 3 &&
                                                                        <Fragment>
                                                                            <div className="container" style={{
                                                                                position: 'relative',
                                                                                textAlign: 'center',
                                                                                padding: '0'
                                                                            }}>
                                                                                <img style={{
                                                                                    width: '100px',
                                                                                    height: '100px',
                                                                                    borderRadius: '4px',
                                                                                    marginRight: '10px',
                                                                                    marginLeft: '10px',
                                                                                    opacity: '0.5'
                                                                                }} src={item.url} alt={`claim_${index}`}
                                                                                     onClick={claimId === undefined ? () => createClaim(`attachAccidentReport`) : () => updateClaim(`attachAccidentReport`)}/>
                                                                                <div style={{
                                                                                    position: 'absolute',
                                                                                    top: '50%',
                                                                                    left: '50%',
                                                                                    transform: 'translate(-50%, -50%)',
                                                                                    fontWeight: '600',
                                                                                    fontSize: '32px',
                                                                                    color: themeContext.color.neutral600
                                                                                }}
                                                                                     onClick={claimId === undefined ? () => createClaim(`attachAccidentReport`) : () => updateClaim(`attachAccidentReport`)}>
                                                                                    +{imagesAccidentalReport.length - 3}</div>
                                                                            </div>
                                                                        </Fragment>
                                                                    }
                                                                </div>
                                                            ))
                                                        }
                                                        {
                                                            !isDesktop && sessionStorage.getItem(`${claimId}_accident_report_images`) && sessionStorage.getItem(`${claimId}_accident_report_images`).length > 0 && imagesAccidentalReport.map((item, index) => (
                                                                <div key={index}>
                                                                    {
                                                                        index <= 1 &&
                                                                        <Fragment>
                                                                            <div className="container" style={{
                                                                                position: 'relative',
                                                                                textAlign: 'center',
                                                                                padding: '0'
                                                                            }}>
                                                                                <img style={{
                                                                                    width: '100px',
                                                                                    height: '100px',
                                                                                    borderRadius: '4px',
                                                                                    marginRight: '10px',
                                                                                    marginLeft: '10px'
                                                                                }} src={item.url} alt={`claim_${index}`}
                                                                                     onClick={claimId === undefined ? () => createClaim(`attachAccidentReport`) : () => updateClaim(`attachAccidentReport`)}/>
                                                                            </div>
                                                                        </Fragment>
                                                                    }
                                                                    {
                                                                        index === 2 &&
                                                                        <Fragment>
                                                                            <div className="container" style={{
                                                                                position: 'relative',
                                                                                textAlign: 'center',
                                                                                padding: '0'
                                                                            }}>
                                                                                <img style={{
                                                                                    width: '100px',
                                                                                    height: '100px',
                                                                                    borderRadius: '4px',
                                                                                    marginRight: '10px',
                                                                                    marginLeft: '10px',
                                                                                    opacity: '0.5'
                                                                                }} src={item.url} alt={`claim_${index}`}
                                                                                     onClick={claimId === undefined ? () => createClaim(`attachAccidentReport`) : () => updateClaim(`attachAccidentReport`)}/>
                                                                                <div style={{
                                                                                    position: 'absolute',
                                                                                    top: '50%',
                                                                                    left: '50%',
                                                                                    transform: 'translate(-50%, -50%)',
                                                                                    fontWeight: '600',
                                                                                    fontSize: '32px',
                                                                                    color: themeContext.color.neutral600
                                                                                }}
                                                                                     onClick={claimId === undefined ? () => createClaim(`attachAccidentReport`) : () => updateClaim(`attachAccidentReport`)}>
                                                                                    +{imagesAccidentalReport.length - 2}</div>
                                                                            </div>
                                                                        </Fragment>
                                                                    }
                                                                </div>
                                                            ))
                                                        }
                                                    </Row>
                                                    <Row className="justify-content-center mb-3">
                                                        <Col>
                                                            <Button name="attachAccidentReport"
                                                                    disabled={isFormInvalid}
                                                                    type={"button"}
                                                                    onClick={claimId === undefined ? () => createClaim(`attachAccidentReport`) : () => updateClaim(`attachAccidentReport`)}
                                                                    className={isFormInvalid ? "btn-custom-disable-2 cursor-pointer" : "btn-primary-custom cursor-pointer"}
                                                                    style={isFormInvalid ? {
                                                                        background: themeContext.color.neutral400,
                                                                        height: '40px',
                                                                        textTransform: 'none',
                                                                        width: isDesktop ? 'max-content' : '-webkit-fill-available'
                                                                    } : {
                                                                        background: themeContext.color.main,
                                                                        height: '40px',
                                                                        textTransform: 'none',
                                                                        width: isDesktop ? 'max-content' : '-webkit-fill-available'
                                                                    }}>
                                                                {
                                                                    isLoadingCreate &&
                                                                    <img src={spinner} className="spinner-img"
                                                                         alt="spinner"/>
                                                                }
                                                                {
                                                                    !isLoadingCreate && t('carClaimForm.append-accident-report-image-label')
                                                                }
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-4 mb-4">
                                                        <Col style={{textAlign: 'left'}}>
                                                        <span
                                                            className="font-weight-bold">{t('carClaimForm.hasAccidentReport-note-1')}</span><br/>
                                                            <span
                                                                style={{color: themeContext.color.neutral400}}>{t('carClaimForm.hasAccidentReport-note-2')}</span><br/>
                                                            <span>{t('carClaimForm.hasAccidentReport-note-3')}</span><br/>
                                                            <span>{t('carClaimForm.hasAccidentReport-note-4')}</span><br/>
                                                            <span>{t('carClaimForm.hasAccidentReport-note-5')}</span><br/>
                                                            <span>{t('carClaimForm.hasAccidentReport-note-6')}</span><br/>
                                                            <span>{t('carClaimForm.hasAccidentReport-note-7')}</span><br/>
                                                            <span>{t('carClaimForm.hasAccidentReport-note-8')}</span><br/>
                                                        </Col>
                                                    </Row>
                                                </Fragment>
                                            }
                                            <Row className="mb-4">
                                                <Col style={{textAlign: 'left'}}>
                                                    <span
                                                        style={{color: themeContext.color.neutral400}}>{t('carClaimForm.required')}</span>
                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Col>
                                                    <Button className="close-button"
                                                            variant="outlined"
                                                            size="large"
                                                            name="send"
                                                            style={{
                                                                color: themeContext.color.white,
                                                                width: '288px',
                                                                textTransform: 'none'
                                                            }}
                                                            type={'submit'}>
                                                        {
                                                            isLoadingSend &&
                                                            <img src={spinner} className="spinner-img" alt="spinner"/>
                                                        }
                                                        {!isLoadingSend && t('carClaimForm.send-button')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Fragment>
                                }
                                {
                                    isSent &&
                                    <Row className="pr-0 pl-0">
                                        <Col className="col-list">
                                            <Container>
                                                <Row>
                                                    <Col>
                                                        <img src={emailPlaceholder} alt="email-placeholder"
                                                             width="100%"
                                                             style={{'maxHeight': '340px'}}/>
                                                    </Col>
                                                </Row>
                                                <Row style={{'marginTop': '43.5px'}}>
                                                    <Col className="pl-5 pr-5">
                                            <span style={{
                                                'fontSize': '16px',
                                                'color': themeContext.color.neutral600,
                                                'fontWeight': 'bold'
                                            }}>{t('carClaimForm.send-title')}</span>
                                                    </Col>
                                                </Row>
                                                <Row style={{
                                                    'marginTop': '12px',
                                                    'marginBottom': '44.5px'
                                                }}>
                                                    <Col>
                                            <span style={{
                                                'fontSize': '14px',
                                                'color': themeContext.color.neutral600,
                                                'fontWeight': 'normal'
                                            }}>{t('carClaimForm.send-payoff-1')}</span><br/>
                                                        <span style={{
                                                            'fontSize': '14px',
                                                            'color': themeContext.color.neutral600,
                                                            'fontWeight': 'normal'
                                                        }}>{t('carClaimForm.send-payoff-2')}</span>
                                                    </Col>
                                                </Row>
                                                <Container
                                                    style={{
                                                        'background': 'transparent',
                                                        'paddingBottom': "40px"
                                                    }}>
                                                    <Row>
                                                        <Col>
                                                            <Button className="btn-custom text-uppercase"
                                                                    variant="outlined"
                                                                    size="large"
                                                                    style={{'color': themeContext.color.white}}
                                                                    onClick={() => navigate('/claims')}
                                                            >{t('carClaimForm.close-button')}</Button>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Container>
                                        </Col>
                                    </Row>
                                }
                            </Fragment>
                    }
                </Container>
                <Dialog
                    onClose={() => {
                        if (new_ids.current.length > 0) {
                            setOpenDoNotSaveDialog(true)
                        } else {
                            setOpenUploadDialog(false)
                        }
                    }}
                    fullScreen={!isDesktop}
                    fullWidth={isDesktop}
                    maxWidth={'sm'}
                    PaperProps={{
                        style: {
                            bottom: isDesktop ? '5%' : '0',
                            color: themeContext.color.neutral600,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                            borderRadius: isDesktop ? '20px' : 'none',
                            height: isDesktop ? '90%' : '100%',
                            marginTop: '10%'
                        }
                    }}
                    open={openUploadDialog}
                    TransitionComponent={Transition}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                                 style={{textAlign: 'center'}}>
                        <Row style={{justifyContent: 'end'}}>
                            <img src={exitIcon} className="exit-button" alt="close"
                                 onClick={() => {
                                     if (new_ids.current.length > 0) {
                                         setOpenDoNotSaveDialog(true)
                                     } else {
                                         setOpenUploadDialog(false)
                                     }
                                 }} style={{float: 'inline-end'}}/>
                        </Row>
                        <Row style={{marginTop: '-12px'}}>
                            <Col className="col">
                                <h6 className="dialog-form-title-1 float-left text-left">{t('attachImagesToClaimForm.title')}</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h6 className="dialog-form-title-2 float-left text-left">
                                    {t('carClaimForm.upload-description')}
                                </h6>
                            </Col>
                        </Row>
                    </DialogTitle>
                    <DialogContent className="no-scrollbar">
                        <div className="dialog-main-container" style={{paddingBottom: '120px'}}>
                            <div style={{display: "flex", gap: "24px"}}
                                 className={isDesktop ? 'flex-wrap justify-content-center mt-4' : 'flex-wrap justify-content-between mt-4'}>
                                <div className={images.length < 10 ? 'add-active-icon add-icon' : 'add-icon'}>
                                    <img key={0} src={addIcon} width={'100%'} height={'100%'}
                                         alt={`image-0`}
                                         onClick={() => attachImageActions(documents.type, images.length)}
                                    />
                                    <img hidden className="trash-button" src={trashIcon} alt="trash-icon"/>
                                    <input hidden id="image-file" type="file" onChange={handleOnPendingImage}
                                           accept="image/*,application/pdf"/>
                                </div>
                                {
                                    images.map((item, index) => (
                                        <div key={index} className={'div-grid'}>
                                            <img src={item.url} alt={`image-${index}`}
                                                 className={'image-grid'}
                                                 onClick={() => {
                                                     if (item.file) {
                                                         window.open(item.file, '_blank', 'noopener', 'noreferrer');
                                                     } else {
                                                         openLightboxFun(images, index)
                                                     }
                                                 }}
                                            />
                                            <img className="trash-button" src={trashIcon} alt="trash-icon"
                                                 onClick={() => openDeleteImageDialog(item.id)}/>
                                        </div>
                                    ))
                                }
                                <FsLightbox
                                    toggler={lightboxController.toggler}
                                    sources={imagesUrl}
                                    type="image"
                                    sourceIndex={lightboxController.slide}
                                    key={imagesUrl.length}
                                />
                            </div>
                            {
                                uploadError &&
                                <Row className="ml-4 mr-4 mt-4">
                                    <Col className="mt-4 text-center dialog-form-title-3 red">
                                        <span dangerouslySetInnerHTML={{__html: uploadError}}/>
                                    </Col>
                                </Row>
                            }
                            <Container className="position-absolute"
                                       style={
                                           isDesktop ? {
                                               background: 'transparent',
                                               opacity: '1',
                                               left: 0,
                                               bottom: 48,
                                               right: 0
                                           } : {
                                               background: 'transparent',
                                               opacity: '1',
                                               left: 0,
                                               bottom: 68,
                                               right: 0
                                           }
                                       }>
                                <Row style={{textAlign: 'center'}}>
                                    <Col>
                                        <Button className="close-button boxShadow"
                                                variant="outlined"
                                                size="large"
                                                style={{
                                                    color: themeContext.color.white,
                                                    width: '288px',
                                                    textTransform: 'none'
                                                }}
                                                type="submit"
                                                disabled={false}
                                                onClick={() => {
                                                    sessionStorage.setItem(`${claimId}_images`, JSON.stringify(ids.current))
                                                    new_ids.current = []
                                                    setOpenUploadDialog(false)
                                                }}
                                        >{t('regulations.save-pdf-button')}</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    onClose={() => {
                        if (new_ids_accidentReport.current.length > 0) {
                            setOpenDoNotSaveDialog(true)
                        } else {
                            setOpenUploadDialogAccidentReport(false)
                        }
                    }}
                    fullScreen={!isDesktop}
                    fullWidth={isDesktop}
                    maxWidth={'sm'}
                    PaperProps={{
                        style: {
                            bottom: isDesktop ? '5%' : '0',
                            color: themeContext.color.neutral600,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                            borderRadius: isDesktop ? '20px' : 'none',
                            height: isDesktop ? '90%' : '100%',
                            marginTop: '10%'
                        }
                    }}
                    open={openUploadDialogAccidentReport}
                    TransitionComponent={Transition}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                                 style={{textAlign: 'center'}}>
                        <Row style={{justifyContent: 'end'}}>
                            <img src={exitIcon} className="exit-button" alt="close"
                                 onClick={() => {
                                     if (new_ids_accidentReport.current.length > 0) {
                                         setOpenDoNotSaveDialog(true)
                                     } else {
                                         setOpenUploadDialogAccidentReport(false)
                                     }
                                 }} style={{float: 'inline-end'}}/>
                        </Row>
                        <Row style={{marginTop: '-12px'}}>
                            <Col className="col">
                                <h6 className="dialog-form-title-1 float-left text-left">{t('attachImagesToClaimForm.title-accident-report')}</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h6 className="dialog-form-title-2 float-left text-left">
                                    {t('carClaimForm.upload-description')}
                                </h6>
                            </Col>
                        </Row>
                    </DialogTitle>
                    <DialogContent className="no-scrollbar">
                        <div className="dialog-main-container" style={{paddingBottom: '120px'}}>
                            <div style={{display: "flex", gap: "24px"}}
                                 className={isDesktop ? 'flex-wrap justify-content-center mt-4' : 'flex-wrap justify-content-between mt-4'}>
                                <div
                                    className={imagesAccidentalReport.length < 5 ? 'add-active-icon add-icon' : 'add-icon'}>
                                    <img key={0} src={addIcon} width={'100%'} height={'100%'}
                                         alt={`image-0`}
                                         onClick={() => attachImageActions(documents.type, imagesAccidentalReport.length)}
                                    />
                                    <img hidden className="trash-button" src={trashIcon} alt="trash-icon"/>
                                    <input hidden id="image-file" type="file" onChange={handleOnPendingImage}
                                           accept="image/*,application/pdf"/>
                                </div>
                                {
                                    imagesAccidentalReport.map((item, index) => (
                                        <div key={index} className={'div-grid'}>
                                            <img src={item.url} alt={`image-${index}`} className={'image-grid'}
                                                 onClick={() => {
                                                     if (item.file) {
                                                         window.open(item.file, '_blank', 'noopener', 'noreferrer');
                                                     } else {
                                                         openLightboxFun(imagesAccidentalReport, index)
                                                     }
                                                 }}
                                            />
                                            <img className="trash-button" src={trashIcon} alt="trash-icon"
                                                 onClick={() => openDeleteImageDialog(item.id)}/>
                                        </div>
                                    ))
                                }
                                <FsLightbox
                                    toggler={lightboxController.toggler}
                                    sources={imagesUrlAccidentalReport}
                                    type="image"
                                    sourceIndex={lightboxController.slide}
                                    key={imagesUrlAccidentalReport.length}
                                />
                            </div>
                            <Container className="position-absolute"
                                       style={
                                           isDesktop ? {
                                               background: 'transparent',
                                               opacity: '1',
                                               left: 0,
                                               bottom: 48,
                                               right: 0
                                           } : {
                                               background: 'transparent',
                                               opacity: '1',
                                               left: 0,
                                               bottom: 68,
                                               right: 0
                                           }
                                       }>
                                <Row style={{textAlign: 'center'}}>
                                    <Col>
                                        <Button className="close-button boxShadow"
                                                variant="outlined"
                                                size="large"
                                                style={{
                                                    color: themeContext.color.white,
                                                    width: '288px',
                                                    textTransform: 'none'
                                                }}
                                                type="submit"
                                                disabled={false}
                                                onClick={() => {
                                                    sessionStorage.setItem(`${claimId}_accident_report_images`, JSON.stringify(ids.current))
                                                    new_ids_accidentReport.current = []
                                                    setOpenUploadDialogAccidentReport(false)
                                                }}
                                        >{t('regulations.save-pdf-button')}</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </DialogContent>
                </Dialog>
                <DialogDoubleAction open={openDialog2}
                                    title={t('genericClaimForm.irreversible-action-title')}
                                    description={t('genericClaimForm.irreversible-action-description')}
                                    handleClose={() => setOpenDialog2(false)}
                                    handleNo={() => setOpenDialog2(false)}
                                    deleteAction={true}
                                    handleYes={() => handleDeleteImage(imageIdToDelete)}
                                    buttonYesText={t('genericClaimForm.irreversible-action-done')}
                                    buttonNoText={t('genericClaimForm.irreversible-action-undone')}/>
                <DialogDoubleAction open={openDoNotSaveDialog}
                                    title={t('genericClaimForm.do-not-save-title')}
                                    description={t('genericClaimForm.do-not-save-description')}
                                    handleClose={() => setOpenDoNotSaveDialog(false)}
                                    handleNo={() => setOpenDoNotSaveDialog(false)}
                                    deleteAction={true}
                                    handleYes={() => doNotSave(activeDialog)}
                                    buttonYesText={t('genericClaimForm.do-not-save-confirm-button')}
                                    buttonNoText={t('genericClaimForm.do-not-save-refuse-button')}/>
                <DialogDoubleAction open={openAccidentReportDialog}
                                    title={t('carClaimForm.accident-report-dialog-title')}
                                    description={t('carClaimForm.accident-report-dialog-description')}
                                    handleClose={() => setOpenAccidentReportDialog(false)}
                                    handleNo={() => setOpenAccidentReportDialog(false)}
                                    deleteAction={true}
                                    handleYes={removeAccidentReportImages}
                                    buttonYesText={t('carClaimForm.accident-report-dialog-done')}
                                    buttonNoText={t('carClaimForm.accident-report-dialog-undone')}/>
                <CropDialog open={openCropDialog}
                            handleAttach={() => {
                                if (isLoadingImg === false) {
                                    attachCroppedImage()
                                }
                            }}
                            handleClose={() => {
                                setOpenCropDialog(false)
                                setIsLoadingImg(false)
                            }}
                            isLoading={isLoadingImg}
                            onPendingImage={onPendingImage}
                            cropperRef={cropperRef}
                            setCropper={(data) => {
                                if (data) {
                                    setCropper(data)
                                }
                            }}/>
            </LocalizationProvider>
    )
}
