import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import back from "../../images/back-icon.svg";
import Container from "react-bootstrap/Container";
import {Link, useNavigate} from "react-router-dom";
import goTo from '../../images/goto-icon.svg'
import logoutIcon from '../../images/logout-icon.svg'
import updateIcon from '../../images/updateApp-icon.svg'
import messagesIcon from '../../images/messages-icon.svg'
import carsIcon from '../../images/vehicles-icon.svg'
import usersIcon from '../../images/users-icon.svg'
import share from '../../images/external-link.svg'
import languageSettings from '../../images/language-icon.svg'
import accountSettings from '../../images/settings-icon.svg'
import {makeStyles} from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import AuthContextMiddleware from "../middleware/AuthContextMiddleware.js";
import FileSaver from "file-saver";
import accountExecMale from "../../images/accountExec-male-icon.svg";
import accountExecFemale from "../../images/accountExec-female-icon.svg";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {Button, Slide, TextField} from "@mui/material";
import personalDocument from "../../images/personal-document-icon.svg";
import {getElementFromIndexedDB, putElementFromIndexedDB} from "../service/UtilityService.js";
import betaFeedback from "../../images/feedback-beta-icon.svg";
import paymentsIcon from "../../images/payment-settings-icon.svg";
import FsLightbox from "fslightbox-react";
import brokerHoverIcon from '../../images/nav-broker-hover.svg';
import alternativeLoginIcon from '../../images/alternative-login-icon.svg';
import exitIcon from "../../images/exit-icon.svg";
import {useMediaQuery} from "react-responsive";
import jwt_decode from "jwt-decode";
import spinner from "../../images/spinner.svg";
import privacyPolicyIcon from '../../images/privacy-policy.svg';
import claimsHoverIcon from '../../images/nav-claims-hover.svg';
import {publish} from "../service/EventService.js";
import PrivacyPolicyDialog from "../components/PrivacyPolicyDialog.js";
import {useTranslation} from "react-i18next";
import supportIcon from "../../images/support-icon.svg"
import themeContext from "../components/ThemeContext.js";
import {StyledBackdrop} from "../styles/Styles.js";
import ErrorPage from "../pages/ErrorPage.js";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    card: {
        margin: "0 auto !important",
        marginBottom: "30px !important",
        background: `${themeContext.color.mainActive} 0% 0% no-repeat padding-box !important`,
        boxShadow: `2px 3px 10px ${themeContext.color.boxShadow} !important`,
        borderRadius: "10px !important",
        opacity: "1 !important",
        height: "190px !important",
    },
    cardTextHigh: {
        color: `${themeContext.color.white} !important`,
        textAlign: 'left !important',
        lineHeight: '1 !important',
        fontWeight: '100 !important',
        fontSize: '12px !important'
    },
    cardTextMid: {
        color: `${themeContext.color.white} !important`,
        textAlign: 'left !important',
        lineHeight: '1 !important',
        fontWeight: 'bold !important',
        fontSize: '21px !important'
    },
    cardTextLow: {
        color: `${themeContext.color.white} !important`,
        textAlign: 'left !important',
        lineHeight: '1.6 !important',
        fontWeight: '600 !important',
        fontSize: '12px !important',
        textDecoration: "underline !important",
        float: 'left !important'
    },
    rootList: {
        width: '100% !important',
        backgroundColor: `${theme.palette.background.paper} !important`,
        padding: "0 0 0 0 !important",
    },
    listItem: {
        color: `${themeContext.color.neutral400} !important`,
        paddingTop: '18px !important',
        paddingBottom: '18px !important',
    },
    listText: {
        fontSize: '18px !important'
    },
    large: {
        width: '64px !important',
        height: '64px !important',
    },
}));

export default function Settings(key) {
    const [noVehicle, setNoVehicle] = useState(sessionStorage.getItem('noVehicles'))
    useEffect(() => {
        if (navigator.onLine) {
            //console.log('map', themeContext)
            getAccountExec();
            getDocumentsForPrivacy();
        } else {
            getAccountExecOffline();
        }

        if (sessionStorage.getItem('noVehicles') === null) {
            getVehicles();
        }
    }, []);

    const accountExecUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/accountExec`;
    const accountExecVCFUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/accountExecVcf`;
    const getPeopleVehiclesUrl = `${process.env.REACT_APP_BASE_URL}/profile/user/peopleVehicles`;
    const isUpdatedUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/isUpdated`;
    const removeTokenUrl = `${process.env.REACT_APP_BASE_URL}/notifications/notify/removeToken`;
    const getDocumentsForPrivacyUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/privacy/documentForPrivacy`;
    const getPrivacyUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/privacy/getPrivacy`;
    const setPrivacyUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/privacy/setPrivacy`;
    const switchLoginUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/switchLogin`;
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const navigate = useNavigate();
    const authCtx = useContext(AuthContextMiddleware);
    const [accountExec, setAccountExec] = useState([]);
    const token = sessionStorage.getItem('token');
    const [noPeople, setNoPeople] = useState(sessionStorage.getItem('noPeople'))
    const [phone, setPhone] = useState("");
    //const [serviceError, setServiceError] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [toggler, setToggler] = useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogUpdate, setOpenDialogUpdate] = React.useState(false);
    const [openAlternativeLoginDialog, setOpenAlternativeLoginDialog] = React.useState(false);
    const [emailTarget, setEmailTarget] = React.useState('');
    const [emailTargetError, setEmailTargetError] = React.useState(null);
    const isDesktop = useMediaQuery({query: '(min-width: 768px)'})
    const [isLoadingAlternativeLogin, setIsLoadingAlternativeLogin] = React.useState(false);
    const [openPolicyDialog, setOpenPolicyDialog] = useState(false);
    const [checkbox1, setCheckbox1] = useState(false);
    const [checkbox2, setCheckbox2] = useState(false);
    const [checkbox3, setCheckbox3] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [privacyPolicyDocument, setPrivacyPolicyDocument] = useState([])
    const [secretShortcut, setSecretShortcut] = useState(1)
    const refreshTokenUrl = `${process.env.REACT_APP_BASE_URL}/management/auth/refresh`;
    const refresh_token = sessionStorage.getItem('refresh_token');
    const telegramWrapperRef = useRef(null);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if(authCtx.telegramUser && process.env.REACT_APP_TELEGRAM_LOGIN) {
            const scriptElement = document.createElement('script');
            scriptElement.src = 'https://telegram.org/js/telegram-widget.js?22';
            scriptElement.setAttribute('data-telegram-login', process.env.REACT_APP_TELEGRAM_LOGIN);
            scriptElement.setAttribute('data-size', 'large');
            scriptElement.setAttribute('data-auth-url', '/telegram-hook' );
            scriptElement.async = true;
            telegramWrapperRef.current.appendChild(scriptElement);
        }
    }, []);

    function turnBack() {
        return navigate('/dashboard');
    }

    function reload() {
        window.location.reload();
    }


    function getAccountExec() {
        fetch(accountExecUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.phone && data.phone.startsWith("+39")) {
                setPhone(data.phone.slice(3))
            }
            setAccountExec(data);
            putElementFromIndexedDB('accountExec', data)
        }).catch(() => {
            setErrorPage(true)
        });
    }

    function getAccountExecOffline() {
        getElementFromIndexedDB('accountExec').then((response) => {
            return response.value;
        }).then((data) => {
            setAccountExec([])
            setAccountExec(data)
        });
    }

    function updateAndLogout(appVersion) {
        fetch(removeTokenUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: sessionStorage.getItem('firebaseToken')
            }),
        }).then((response) => {
            console.log(response)
            fetch(isUpdatedUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                return response.json();
            }).then(async (res) => {
                if (res.ok) {
                    if (res.message > appVersion) {
                        navigator.serviceWorker.getRegistrations().then((r) => {
                            return Promise.all(r.map(reg => reg.unregister()));
                        }).then(() => {
                            let username = localStorage.getItem('username-wide')
                            let biometrics = localStorage.getItem('biometrics')
                            let webauthn = localStorage.getItem('webauthn')
                            let firstLogin = false;
                            if (localStorage.getItem('firstLogin')) {
                                firstLogin = localStorage.getItem('firstLogin')
                            }
                            authCtx.logout();
                            sessionStorage.clear();
                            localStorage.clear();
                            if (username !== null) {
                                localStorage.setItem('username-wide', username);
                            }
                            if (biometrics !== null) {
                                localStorage.setItem('biometrics', biometrics);
                            }
                            if (webauthn !== null) {
                                localStorage.setItem('webauthn', webauthn);
                            }
                            if (firstLogin === true) {
                                localStorage.setItem('firstLogin', firstLogin);
                            }
                            window.location.reload();
                        });
                    } else {
                        authCtx.logout();
                        window.location.reload();
                    }
                }
            }).catch(() => {
                authCtx.logout();
                window.location.reload();
            })
        })
    }

    async function handleUpdate(event) {
        navigator.serviceWorker.getRegistrations().then((r) => {
            return Promise.all(r.map(reg => reg.unregister()));
        }).then(() => {
            fetch(removeTokenUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: sessionStorage.getItem('firebaseToken')
                }),
            }).then((response) => {
                console.log(response)
                fetch(isUpdatedUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }).then((response) => {
                    return response.json();
                }).then(async (res) => {
                    sessionStorage.clear();
                    localStorage.clear();
                    window.location.reload();
                }).catch(() => {
                    authCtx.logout();
                    window.location.reload();
                })
            })
        });
    }

    function shareVCard() {
        fetch(`${accountExecVCFUrl}`, {
            method: 'GET',
            headers: {
                //'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        }).then((res) => {
            if (res) {
                return res.json();
            }
        }).then((res) => {
            fetch(res.message, {
                method: 'GET',
            }).then((res) => {
                return res.text();
            }).then((res) => {
                let file = new Blob([res], {type: "text/vcard;charset=utf-8"});
                FileSaver.saveAs(file, `${accountExec.firstName}_${accountExec.lastName}`);
            });
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClickUpdateOpen = async () => {
        setOpenDialogUpdate(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleCloseUpdate = () => {
        setOpenDialogUpdate(false);
    };

    const getVehicles = () => {
        fetch(getPeopleVehiclesUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            let length = Object.keys(data).length;
            if (length === 0) {
                setNoVehicle(true);
                sessionStorage.setItem('noVehicles', true)
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const switchLogin = (e) => {
        e.preventDefault();
        setIsLoadingAlternativeLogin(true)
        fetch(switchLoginUrl, {
            method: 'POST',
            body: JSON.stringify({
                email: emailTarget,
            }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                setEmailTargetError(t('settings.email-target-error'));
                return res.text().then(text => {
                    setIsLoadingAlternativeLogin(false)
                    throw new Error(text)
                })
            }
        }).then((data) => {
            if (data) {
                if (data.isLegacy === true) {
                    setIsLoadingAlternativeLogin(false)
                    postRedirect(`${process.env.REACT_APP_MY_WIDE_URL}`, {
                        accessToken: data.accessToken,
                        expiresIn: data.expiresIn,
                        isLegacy: data.isLegacy,
                        pinCode: data.pinCode,
                        refreshExpiresIn: data.pinCode,
                        refreshToken: data.refreshToken,
                        userId: data.userId
                    })
                } else {
                    let decoded = jwt_decode(data.accessToken);
                    if (localStorage.getItem('username-wide')) {
                        let prevUsername = localStorage.getItem('username-wide');
                        if (prevUsername !== decoded.email) {
                            sessionStorage.clear();
                            localStorage.removeItem('webauthn')
                        }
                    }
                    localStorage.setItem('username-wide', decoded.email);
                    sessionStorage.removeItem('login_error');
                    authCtx.login(data.accessToken, data.refreshToken, data.pinCode);
                    setIsLoadingAlternativeLogin(false)
                    navigate('/dashboard');
                }
            } else {
                setEmailTargetError(t('settings.email-target-error'));
                setIsLoadingAlternativeLogin(false)
            }
        }).catch(() => {
            setEmailTargetError(t('settings.email-target-error'));
            setIsLoadingAlternativeLogin(false)
        });
    }

    const getDocumentsForPrivacy = () => {
        setIsLoadingData(true)
        fetch(getDocumentsForPrivacyUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            setPrivacyPolicyDocument(data[0]);
            getPrivacy();
        }).catch(() => {
            setErrorPage(true)
        })
    }

    const getPrivacy = () => {
        setIsLoadingData(true)
        fetch(getPrivacyUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data) {
                setCheckbox1(data.datiParticolari)
                setCheckbox2(data.marketingDiretto)
                setCheckbox3(data.profilazione)
                setIsLoadingData(false)
            }
        }).catch(() => {
            setErrorPage(true)
        })
    }

    function setPrivacy(checkbox1, checkbox2, checkbox3) {
        fetch(setPrivacyUrl, {
            method: 'POST',
            body: JSON.stringify({
                datiParticolari: checkbox1,
                marketingDiretto: checkbox2,
                profilazione: checkbox3,
                //noShow: noShow,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            if (response.ok) {
                setOpenPolicyDialog(false)
                getPrivacy();
            }
        }).catch(e => {
        });
    }

    const navigateToClaims = () => {
        publish('navigateToClaims');
        sessionStorage.setItem('root', '/claims');
        return navigate(`/claims`);
    }

    const navigateToMyWide01 = () => {
        refresh(refresh_token).then(data => {
            postRedirect(`${process.env.REACT_APP_MY_WIDE_URL}`, {
                accessToken: data.accessToken,
                expiresIn: data.expiresIn,
                isLegacy: data.isLegacy,
                pinCode: data.pinCode,
                refreshExpiresIn: data.pinCode,
                refreshToken: data.refreshToken,
                userId: data.userId
            })
        }).catch((e) => {
            console.log('error', e)
        });
    }

    function postRedirect(path, params, method = 'post') {
        console.log('post redirect')
        const form = document.createElement('form');
        form.method = method;
        form.action = path;
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = params[key];
                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
    }

    const refresh = (refreshToken) => {
        return fetch(refreshTokenUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                refreshToken: refreshToken
            }),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                if (res.status === 401) {
                    return res.json().then((data) => {
                        let errorMessage = '401';
                        if (data && data.error && data.error.message) {
                            errorMessage = data.error.message;
                        }
                        throw new Error(errorMessage);
                    });
                } else {
                    return res.json().then((data) => {
                        let errorMessage = '500';
                        if (data && data.error && data.error.message) {
                            errorMessage = data.error.message;
                        }
                        throw new Error(errorMessage);
                    });
                }
            }
        }).catch((e) => {
            console.log('error', e)
        });
    }

    return (
        errorPage ?
            <ErrorPage/> :
            <Fragment>
                <Container className="mt-0 full-height" style={{'backgroundColor': themeContext.color.white}}>
                    <Row className="nav-top container border-bottom" style={{
                        'paddingLeft': '0',
                        'paddingRight': '0',
                        //'borderLeft': '1px solid #EEE9E5',
                        //'borderRight': '1px solid #EEE9E5'
                    }}>
                        <Col>
                            <Row className="first-nav-top-row-shadow">
                                <Col className="col-4 float-left">
                                    <img src={back} onClick={turnBack}
                                         className="nav-top-logo float-left cursor-pointer"
                                         height="27px"
                                         alt="back"/>
                                </Col>
                                <Col className="col-4">
                                    <h6 className="header-title ellipsis-1-v2" onClick={() => {
                                        setSecretShortcut(current => current + 1)
                                        if (secretShortcut === 8) {
                                            navigateToMyWide01();
                                            setSecretShortcut(1)
                                        }
                                    }}>{t('settings.title')}</h6>
                                </Col>
                                {
                                    /*
                                    <Col className="col-4 float-right">
                                        <img src={darkModeImg} className="nav-top-logo float-right cursor-pointer" height="27px"
                                             alt="dots"/>
                                    </Col>
                                    */
                                }
                            </Row>
                            <Row style={{
                                background: themeContext.color.white,
                                paddingTop: '11px'
                            }}>
                                <Col>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Row>
                                                <Col className="col-3">
                                                    {
                                                        accountExec.photoUrl === null ?
                                                            <Avatar alt="avatar"
                                                                    src={accountExec.gender === 'F' ? accountExecFemale : accountExecMale}
                                                                    className={classes.large}/>
                                                            :
                                                            <Avatar alt="avatar"
                                                                    src={accountExec.photoUrl}
                                                                    className={classes.large}
                                                                    onClick={() => setToggler(!toggler)}/>
                                                    }
                                                </Col>
                                                <Col className="col-7">
                                                    <Row className="mt-2">
                                                        <Col>
                                                            <h6 className={classes.cardTextHigh}>{t('settings.card-title')}</h6>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-2">
                                                        <Col>
                                                            <h3 className={classes.cardTextMid}>{accountExec.firstName}</h3>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h3 className={classes.cardTextMid}>{accountExec.lastName}</h3>
                                                        </Col>
                                                    </Row>
                                                    {accountExec.phone !== "" && accountExec.phone !== null && phone !== "" &&
                                                        <Row className="mt-2 float-left w-100">
                                                            <Col>
                                                                <a id="accountExec-phone"
                                                                   href={`tel:${accountExec.phone}`}
                                                                   className={classes.cardTextLow}>+39 {phone}</a>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    {accountExec.phone !== "" && accountExec.phone !== null && phone === "" &&
                                                        <Row className="mt-2 float-left w-100">
                                                            <Col>
                                                                <a id="accountExec-phone"
                                                                   href={`tel:${accountExec.phone}`}
                                                                   className={classes.cardTextLow}>{accountExec.phone}</a>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <Row className="mt-2 float-left w-100">
                                                        <Col>
                                                            <a id="accountExec-email"
                                                               href={`mailto:${accountExec.email}`}
                                                               className={classes.cardTextLow}>{accountExec.email}</a>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="col-2">
                                                    <img src={share} alt="external-link" height="15px"
                                                         className="float-right"
                                                         onClick={shareVCard}/>
                                                </Col>
                                            </Row>
                                        </CardContent>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <FsLightbox
                        toggler={toggler}
                        sources={[accountExec.photoUrl]}
                        type="image"
                    />
                    <Container className="top-of-the-settings"></Container>

                    <Container style={{
                        'paddingLeft': '0',
                        'paddingRight': '0',
                    }}>
                        <Row>
                            <Col style={{
                                'paddingLeft': '0',
                                'paddingRight': '0px'
                            }}>
                                <div className={classes.rootList}>
                                    <List component="nav">
                                        <Link to="/settings/contacts" style={{'textDecoration': 'none'}}>
                                            <ListItem className={classes.listItem} button>
                                                <ListItemIcon>
                                                    <img src={brokerHoverIcon} alt="image" width="28px"/>
                                                </ListItemIcon>
                                                <ListItemText primary={t('app.nav-agent')}
                                                              className={classes.listText}/>
                                                <ListItemIcon className="justify-content-end">
                                                    <img src={goTo} alt="image" height="20px"/>
                                                </ListItemIcon>
                                            </ListItem>
                                        </Link>
                                        <Divider/>
                                        <ListItem className={classes.listItem} onClick={navigateToClaims} button>
                                            <ListItemIcon>
                                                <img src={claimsHoverIcon} alt="image" width="28px"/>
                                            </ListItemIcon>
                                            <ListItemText primary={t('app.nav-claims')}
                                                          className={classes.listText}/>
                                            <ListItemIcon className="justify-content-end">
                                                <img src={goTo} alt="image" height="20px"/>
                                            </ListItemIcon>
                                        </ListItem>
                                        <Divider/>
                                        {!noPeople &&
                                            <Fragment>
                                                <Link to="/settings/people" style={{'textDecoration': 'none'}}>
                                                    <ListItem className={classes.listItem} button>
                                                        <ListItemIcon>
                                                            <img src={usersIcon} alt="image" width="28px"/>
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('settings.list-users')}
                                                                      className={classes.listText}/>
                                                        <ListItemIcon className="justify-content-end">
                                                            <img src={goTo} alt="image" height="20px"/>
                                                        </ListItemIcon>
                                                    </ListItem>
                                                </Link>
                                                <Divider/>
                                            </Fragment>
                                        }
                                        <Link to="/settings/payments" style={{'textDecoration': 'none'}}>
                                            <ListItem className={classes.listItem} button>
                                                <ListItemIcon>
                                                    <img src={paymentsIcon} alt="image" width="28px"/>
                                                </ListItemIcon>
                                                <ListItemText primary={t('settings.list-payments')}
                                                              className={classes.listText}/>
                                                <ListItemIcon className="justify-content-end">
                                                    <img src={goTo} alt="image" height="20px"/>
                                                </ListItemIcon>
                                            </ListItem>
                                        </Link>
                                        <Divider/>
                                        {!noVehicle &&
                                            <Fragment>
                                                <Link to="/settings/vehicles" style={{'textDecoration': 'none'}}>
                                                    <ListItem className={classes.listItem} button>
                                                        <ListItemIcon>
                                                            <img src={carsIcon} alt="image" width="28px"/>
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('settings.list-vehicles')}
                                                                      className={classes.listText}/>
                                                        <ListItemIcon className="justify-content-end">
                                                            <img src={goTo} alt="image" height="20px"/>
                                                        </ListItemIcon>
                                                    </ListItem>
                                                </Link>
                                                <Divider/>
                                            </Fragment>
                                        }
                                        <Link to="/settings/personal-documents" style={{'textDecoration': 'none'}}>
                                            <ListItem className={classes.listItem} button>
                                                <ListItemIcon>
                                                    <img src={personalDocument} alt="image" width="28px"/>
                                                </ListItemIcon>
                                                <ListItemText primary={t('settings.list-personal-documents')}
                                                              className={classes.listText}/>
                                                <ListItemIcon className="justify-content-end">
                                                    <img src={goTo} alt="image" height="20px"/>
                                                </ListItemIcon>
                                            </ListItem>
                                        </Link>
                                        <Divider/>
                                        <Link to="/settings/profile" style={{'textDecoration': 'none'}}>
                                            <ListItem className={classes.listItem} button>
                                                <ListItemIcon>
                                                    <img src={accountSettings} alt="image" width="28px"/>
                                                </ListItemIcon>
                                                <ListItemText primary={t('settings.list-profile-settings')}
                                                              className={classes.listText}/>
                                                <ListItemIcon className="justify-content-end">
                                                    <img src={goTo} alt="image" height="20px"/>
                                                </ListItemIcon>
                                            </ListItem>
                                        </Link>
                                        <Divider/>
                                        <ListItem className={classes.listItem} button disabled={isLoadingData}
                                                  onClick={() => {
                                                      setOpenPolicyDialog(true)
                                                      sessionStorage.setItem('privacy_policy_dialog', true)
                                                  }}>
                                            <ListItemIcon>
                                                <img src={privacyPolicyIcon} alt="image" width="28px"/>
                                            </ListItemIcon>
                                            <ListItemText primary={t('settings.list-permissions')}
                                                          className={classes.listText}/>
                                            <ListItemIcon className="justify-content-end">
                                                <img src={goTo} alt="image" height="20px"/>
                                            </ListItemIcon>
                                        </ListItem>
                                        <Divider/>
                                        <Link to="/settings/language" style={{'textDecoration': 'none'}}>
                                            <ListItem className={classes.listItem} button>
                                                <ListItemIcon>
                                                    <img src={languageSettings} alt="image" width="28px"/>
                                                </ListItemIcon>
                                                <ListItemText primary={t('settings.list-language')}
                                                              className={classes.listText}/>
                                                <ListItemIcon className="justify-content-end">
                                                    <img src={goTo} alt="image" height="20px"/>
                                                </ListItemIcon>
                                            </ListItem>
                                        </Link>
                                        <Divider/>
                                        <Link to="/settings/message-preferences" style={{'textDecoration': 'none'}}>
                                            <ListItem className={classes.listItem} button>
                                                <ListItemIcon>
                                                    <img src={messagesIcon} alt="image" width="28px"/>
                                                </ListItemIcon>
                                                <ListItemText primary={t('settings.list-messages-preferences')}
                                                              className={classes.listText}/>
                                                <ListItemIcon className="justify-content-end">
                                                    <img src={goTo} alt="image" height="20px"/>
                                                </ListItemIcon>
                                            </ListItem>
                                        </Link>
                                        <Divider/>
                                        <Link to="/settings/support" style={{'textDecoration': 'none'}}>
                                            <ListItem className={classes.listItem} button>
                                                <ListItemIcon>
                                                    <img src={supportIcon} alt="image" width="28px"/>
                                                </ListItemIcon>
                                                <ListItemText primary={t('settings.list-support')}
                                                              className={classes.listText}/>
                                                <ListItemIcon className="justify-content-end">
                                                    <img src={goTo} alt="image" height="20px"/>
                                                </ListItemIcon>
                                            </ListItem>
                                        </Link>
                                        <Divider/>
                                        {process.env.REACT_APP_ENV !== "PROD" &&
                                            <Fragment>
                                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSe-lSIN2O-0FOZ1-evCZxJ8u61NdU1vevfBf1t6gq6F5pAYjg/viewform?usp=sf_link"
                                                   target="_blank" style={{'textDecoration': 'none'}}
                                                   rel="noopener noreferrer">
                                                    <ListItem className={classes.listItem} button>
                                                        <ListItemIcon>
                                                            <img src={betaFeedback} alt="image" width="28px"/>
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('settings.list-feedback-beta')}
                                                                      className={classes.listText}/>
                                                    </ListItem>
                                                </a>
                                                <Divider/>
                                                <ListItem className={classes.listItem} onClick={handleClickUpdateOpen}
                                                          button>
                                                    <ListItemIcon>
                                                        <img src={updateIcon} alt="image" width="28px"/>
                                                    </ListItemIcon>
                                                    <ListItemText primary={t('settings.update')}
                                                                  className={classes.listText}/>
                                                </ListItem>
                                                <Divider/>
                                            </Fragment>
                                        }
                                        {authCtx.switchUser &&
                                            <Fragment>
                                                <ListItem className={classes.listItem}
                                                          onClick={() => setOpenAlternativeLoginDialog(true)} button>
                                                    <ListItemIcon>
                                                        <img src={alternativeLoginIcon} alt="image" width="28px"/>
                                                    </ListItemIcon>
                                                    <ListItemText primary={t('settings.list-alternative-login')}
                                                                  className={classes.listText}/>
                                                </ListItem>
                                                <Divider/>
                                            </Fragment>
                                        }
                                        {authCtx.telegramUser &&
                                            <Fragment>
                                                <ListItem className={classes.listItem}>
                                                    <div ref={ telegramWrapperRef }></div>
                                                    <ListItemText primary={' - Beta'}
                                                                  className={classes.listText} style={{marginLeft: '6px'}}/>
                                                </ListItem>
                                                <Divider/>
                                            </Fragment>
                                        }
                                        <ListItem className={classes.listItem} onClick={handleClickOpen} button>
                                            <ListItemIcon>
                                                <img src={logoutIcon} alt="image" width="28px"/>
                                            </ListItemIcon>
                                            <ListItemText primary={t('settings.list-logout')}
                                                          className={classes.listText}/>
                                        </ListItem>
                                    </List>
                                </div>
                            </Col>
                        </Row>
                        <Container className="bottom-with-footer"></Container>
                    </Container>
                    <Container className="pb-3 fixed-bottom" style={{
                        'background': themeContext.color.white,
                    }}>
                        <Row>
                            <Col>
                                <h6 className="footer-text">{t('settings.footer-text')}{process.env.REACT_APP_VERSION}</h6>
                            </Col>
                        </Row>
                    </Container>
                    <Dialog
                        components={{Backdrop: StyledBackdrop}}
                        open={openDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{
                            style: {
                                'position': 'absolute',
                                'bottom': '0',
                                'left': 'auto',
                                'right': 'auto',
                                'width': '95%',
                                'borderRadius': '10px',
                                'color': themeContext.color.neutral600
                            },
                        }}>
                        <DialogTitle id="alert-dialog-slide-title"
                                     style={{'textAlign': 'center'}}>{t('settings.modal-title')}
                        </DialogTitle>
                        <Divider/>
                        <DialogActions>
                            <Button onClick={() => updateAndLogout(process.env.REACT_APP_VERSION)} color="primary"
                                    fullWidth
                                    style={{'textTransform': 'none'}}>
                                {t('settings.modal-confirm-button')}
                            </Button>
                        </DialogActions>
                        <Divider/>
                        <DialogActions>
                            <Button onClick={handleClose} style={{
                                'color': themeContext.color.neutral400,
                                'textTransform': 'none'
                            }}
                                    fullWidth>
                                {t('settings.modal-refuse-button')}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        components={{Backdrop: StyledBackdrop}}
                        open={openDialogUpdate}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseUpdate}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{
                            style: {
                                'position': 'absolute',
                                'bottom': '0',
                                'left': 'auto',
                                'right': 'auto',
                                'width': '95%',
                                'borderRadius': '10px',
                                'color': themeContext.color.neutral600
                            },
                        }}>
                        <DialogTitle id="alert-dialog-slide-title"
                                     style={{'textAlign': 'center'}}>{t('settings.modal-update-title')}
                        </DialogTitle>
                        <DialogContent style={{'textAlign': 'center'}}>
                            {t('settings.modal-update-title-info')}
                        </DialogContent>
                        <Divider/>
                        <DialogActions>
                            <Button onClick={handleUpdate} color="primary" fullWidth style={{'textTransform': 'none'}}>
                                {t('settings.modal-update-button')}
                            </Button>
                        </DialogActions>
                        <Divider/>
                        <DialogActions>
                            <Button onClick={handleCloseUpdate} style={{
                                'color': themeContext.color.neutral400,
                                'textTransform': 'none'
                            }}
                                    fullWidth>
                                {t('settings.modal-refuse-button')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        onClose={() => {
                            setOpenAlternativeLoginDialog(false)
                            setEmailTargetError(null);
                            setEmailTarget('');
                        }}
                        fullScreen={!isDesktop}
                        fullWidth={isDesktop}
                        maxWidth={'sm'}
                        PaperProps={{
                            style: {
                                bottom: isDesktop ? '5%' : '0',
                                color: themeContext.color.neutral600,
                                borderTopLeftRadius: '20px',
                                borderTopRightRadius: '20px',
                                borderRadius: isDesktop ? '20px' : 'none',
                                height: isDesktop ? '90%' : '100%',
                                marginTop: '10%'
                            }
                        }}
                        open={openAlternativeLoginDialog}
                        TransitionComponent={Transition}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description">

                        <DialogTitle id="alert-dialog-slide-title" className="dialog-title"
                                     style={{textAlign: 'center'}}>
                            <Row style={{justifyContent: 'end'}}>
                                <img src={exitIcon} className="exit-button" alt="close" onClick={() => {
                                    setOpenAlternativeLoginDialog(false)
                                }} style={{float: 'inline-end'}}/>
                            </Row>
                            <Row style={{marginTop: '-12px'}}>
                                <Col className="col">
                                    <h6 className="dialog-form-title-1 float-left text-left">{t('settings.list-alternative-login')}</h6>
                                </Col>
                            </Row>
                        </DialogTitle>
                        <Form onSubmit={switchLogin}>
                            <DialogContent className="no-scrollbar">
                                <div className="dialog-main-container">
                                    <Row className="mt-2">
                                        <Col>
                                            <h6 className="dialog-form-title-2">
                                                {t('settings.email-target')}
                                            </h6>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <TextField
                                                value={emailTarget}
                                                onChange={(e) => {
                                                    setEmailTarget(e.target.value)
                                                    if (!!emailTargetError) {
                                                        setEmailTargetError(null)
                                                    }
                                                }}
                                                id={`email`}
                                                name={'email'}
                                                fullWidth
                                                placeholder=""
                                                variant="outlined"
                                                required={true}
                                                type="email"
                                                color="primary"
                                                error={!!emailTargetError}
                                                helperText={emailTargetError}
                                                InputProps={{
                                                    style: {
                                                        'borderRadius': '4px',
                                                        color: themeContext.color.neutral600
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Container className="position-absolute"
                                               style={
                                                   isDesktop ? {
                                                       background: 'transparent',
                                                       opacity: '1',
                                                       left: 0,
                                                       bottom: 48,
                                                       right: 0
                                                   } : {
                                                       background: 'transparent',
                                                       opacity: '1',
                                                       left: 0,
                                                       bottom: 68,
                                                       right: 0
                                                   }
                                               }>
                                        <Row style={{textAlign: 'center'}}>
                                            <Col>
                                                <Button className="close-button"
                                                        variant="outlined"
                                                        size="large"
                                                        style={{
                                                            color: themeContext.color.white,
                                                            width: '288px',
                                                            textTransform: 'none'
                                                        }}
                                                        type="submit"
                                                        disabled={false}>
                                                    {isLoadingAlternativeLogin ?
                                                        <img src={spinner} className="spinner-img"
                                                             alt="spinner"/> : t('settings.alternative-login-button')}</Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </DialogContent>
                        </Form>
                    </Dialog>
                    {openPolicyDialog &&
                        <PrivacyPolicyDialog open={openPolicyDialog} checkbox1={checkbox1} checkbox2={checkbox2}
                                             document={privacyPolicyDocument}
                                             checkbox3={checkbox3}
                                             handleClose={() => {
                                                 setOpenPolicyDialog(false)
                                             }} proceedLater={false}
                                             handleSave={(checkbox1, checkbox2, checkbox3) => {
                                                 setPrivacy(checkbox1, checkbox2, checkbox3)
                                             }}
                        />}
                </Container>
            </Fragment>
    );
}
