import {useNavigate} from "react-router-dom";
import {useContext, useEffect} from "react";
import {InterceptorContext} from "./InterceptorContext.js";

let apiList;

export default function FetchMiddleware() {
    const originalFetch = window.fetch;
    const navigate = useNavigate()
    const whitelistApi = `${process.env.REACT_APP_BASE_URL}/management/retrieveWhitelistApi`;
    const {handleTitle, handleMessage} = useContext(InterceptorContext);

    useEffect(() => {
        fetch(whitelistApi, {
            method: 'GET',
            header: {
                'Content-Type': 'application/js'
            },
        }).then((res) => {
            return res.json();
        }).then((data) => {
            window.apiList = data;
            apiList = data;
        });
    }, []);

    window.fetch = async function (url, options) {
        let startUrl = window.location.pathname;
        try {
            const result = await originalFetch(url, options)
            if (startUrl === window.location.pathname && !window.location.pathname.includes('login')) {
                if (result.status && result.status !== 401 && result.status >= 300 && !url.includes('vidi')) {
                    const apiValue = apiList.find(api => url.includes(api.url));
                    if (!apiValue) {
                        handleTitle(startUrl);
                        handleMessage(result.statusText, result.ok, result.status, result.url);
                        navigate('/error-page')
                    } else {
                        if(apiValue.stop) {
                            navigate('/service-error')
                        }
                    }
                }
            }
            return result;
        } catch (error) {
            // Handle the error here
            return null; // or return a default response
        }
    };
    return null;
}
